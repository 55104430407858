import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VAULT_SLICE_REDUCER_PATH, vaultSliceInitialState, VaultSliceType } from './constants';


const vaultSlice = createSlice({
  name: VAULT_SLICE_REDUCER_PATH,
  initialState: vaultSliceInitialState,
  reducers: {
    resetVaultState: () => {
      return vaultSliceInitialState;
    },
    setCreateVaultStage: (state, action: PayloadAction<Pick<VaultSliceType, 'stage'>>) => ({
      ...state,
      ...action.payload,
    }),
    setVaultPlan: (state, { payload }: PayloadAction<{ duration: number; rate: number; label: string; name: string }>) => {
      state.plan = payload;
    },
  },
});

export const vaultReducer = vaultSlice.reducer;

export const {
  resetVaultState,
  setCreateVaultStage,
  setVaultPlan
} = vaultSlice.actions;
