import http from 'services/http';
import { GeneralApiResponse, PaginatedApiResponse } from 'types';

import { Airtime } from './constants';
import { BuyAirtimeInput, BuyBettingInput, BuyCableInput, BuyDataInput, BuyElectricityInput, BuyGiftcardInput, GiftCard, VerifyBettingInput, VerifyCableInput, VerifyElectricityInput } from './types';

export const getGiftcards = async ({
  page,
  name
}: {
  page: number;
    name: string
}): Promise<PaginatedApiResponse<GiftCard[]>> => {
  const res = await http.get(`/refills?page=${page}&size=20&filters=[["type",1],["AND"],["name","like","${name}"]]`);
  return res.data;
};

export const getRefills = async ({
  page,
  country,
  type
}: {
  page: number;
  country: string
  type: number
}): Promise<PaginatedApiResponse<Airtime[]>> => {
  const res = await http.get(`/refills?page={${page}}&size=20&filters=["type",${type}]&country=${country}`);
  return res.data;
};

export const getFeaturedGiftcards = async ({
  page,
}: {
  page: number;
}): Promise<PaginatedApiResponse<GiftCard[]>> => {
  const res = await http.get(
    `/refills?page={${page}}&size=10&filters=[["type",1],["and"],["featured",true]]`
  );
  return res.data;
};

export const postBuyGiftcard = async (data: BuyGiftcardInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/gift-cards', data);
  return res.data;
};

export const postBuyAirtime = async (data: BuyAirtimeInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/airtime', data);
  return res.data;
};

export const postBuyBetting = async (data: BuyBettingInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/betting', data);
  return res.data;
};

export const postBuyElectricity = async (data: BuyElectricityInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/electricity', data);
  return res.data;
};

export const postBuyCable = async (data: BuyCableInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/cable-tv', data);
  return res.data;
};

export const postBuyData = async (data: BuyDataInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/data', data);
  return res.data;
};


export const postVerifyBetting = async (data: VerifyBettingInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/betting/verify', data);
  return res.data;
};

export const postVerifyElectricity = async (data: VerifyElectricityInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/electricity/verify', data);
  return res.data;
};

export const postVerifyCable = async (data: VerifyCableInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/refills/cable-tv/verify', data);
  return res.data;
};