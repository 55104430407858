import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Input from 'components/Inputs/input';
import Loader from 'components/Loader';
import { CarouselContent, CarouselItem } from 'components/ui/carousel';
import { useFormik } from 'formik';
import { useCarousel } from 'hooks/useCarousel';
import { cn } from 'lib/utils';
import { ChevronLeft } from 'lucide-react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CARDS_ROUTE } from 'routes/path';
import { useGetCards, useUpdateCard } from 'services/cards';
import { useAppDispatch, useAppSelector } from 'store';
import { setPhysicalCardColor, setVirtualCardColor } from 'store/slices/cards/cardsSlice';
import {
  CARD_SLICE_REDUCER_PATH,
  ColorType,
  PHYSICAL_CARD_COLOR_KEY,
  physicalCardList,
  VIRTUAL_CARD_COLOR_KEY,
  virtualCardList,
} from 'store/slices/cards/constants';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';
import { array, object, string } from 'yup';

import EmptyCard from './emptyCard';

const EditColor = () => {
  const navigate = useNavigate();

  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();

  const { id } = useParams();

  const { api } = useCarousel();

  const card = getCardsResponse?.data.find((card) => card.id === id);

  const dispatch = useAppDispatch();

  const {
    [PHYSICAL_CARD_COLOR_KEY]: physicalCardColor,
    [VIRTUAL_CARD_COLOR_KEY]: virtualCardColor,
  } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const { mutate: updateCard, isPending: isLoadingUpdateCard } = useUpdateCard();

  const arraysAreEqual = (a: string[], b: string[]) =>
    a.length === b.length && a.every((value, index) => value === b[index]);

  useEffect(() => {
    if (card) {
      const color = virtualCardList.find(
        (x) =>
          x.oxFormat.length === card.info.colors.length &&
          x.oxFormat.every((value, index) => value === card.info.colors[index])
      );
      dispatch(setVirtualCardColor(color as ColorType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const formik = useFormik({
    initialValues: {
      label: '',
      colors: [],
    },
    validationSchema: object().shape({
      label: string(),
      colors: array(),
    }),
    onSubmit: async (values) => {
      const newValues = {
        ...values,
        colors:
          card?.info.type === 'virtual' ? virtualCardColor.oxFormat : physicalCardColor.oxFormat,
        label: values.label.length ? values.label : (card?.info.label as string),
      };
      try {
        updateCard(
          { id: id ?? '', ...newValues },
          {
            onSuccess: () => {
              formik.resetForm();
              navigate(CARDS_ROUTE);
              notifySuccess(`Card edit successful`);
            },
            onError: (err) => {
              handleErrors(err);
            },
          }
        );
      } catch (err) {
        handleErrors(err);
      }
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return (
    <SubRouteLayout header="Edit card">
      {card && !isLoadingGetCardsResponse ? (
        <form onSubmit={formik.handleSubmit} className="pb-20 xl:-ml-[4.688rem]">
          <div className="xl:flex items-start xl:space-x-11">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="space-y-10 max-w-[37.5rem]">
              <div className="space-y-3">
                <p className="text-lg md:text-2xl font-medium capitalize">
                  Edit your {card.info.type} card
                </p>
              </div>
              <p>Select card design</p>

              <CarouselContent>
                {card.info.type === 'physical' &&
                  physicalCardList.map((color, index) => (
                    <CarouselItem key={index} className="basis[90%] md:basis-[70%]">
                      <EmptyCard
                        type={card.info.type as 'physical' | 'virtual'}
                        background={color.class}
                      />
                    </CarouselItem>
                  ))}
                {card.info.type === 'virtual' &&
                  virtualCardList.map((color, index) => (
                    <CarouselItem key={index} className="basis[90%] md:basis-[70%]">
                      <EmptyCard
                        type={card.info.type as 'physical' | 'virtual'}
                        background={color.class}
                      />
                    </CarouselItem>
                  ))}
              </CarouselContent>
              <div className="flex items-center justify-center gap-3 mt-8">
                {card.info.type === 'physical' &&
                  physicalCardList.map((color, index) => {
                    arraysAreEqual(color.oxFormat, physicalCardColor.oxFormat) &&
                      api?.scrollTo(index);
                    return (
                      <button
                        aria-label={`slide carousel to index ${index + 1}`}
                        key={`selector-${index}`}
                        className={cn([
                          'border-4 border-transparent scale-75 h-8 w-8 rounded-full',
                          arraysAreEqual(color.oxFormat, physicalCardColor.oxFormat) &&
                            'border-white shadow-lg scale-125',
                        ])}
                        style={{ background: color.class }}
                        type="button"
                        onClick={() => {
                          api?.scrollTo(index);
                          dispatch(setPhysicalCardColor(color));
                        }}
                      />
                    );
                  })}
                {card.info.type === 'virtual' &&
                  virtualCardList.map((color, index) => {
                    arraysAreEqual(color.oxFormat, virtualCardColor.oxFormat) &&
                      api?.scrollTo(index);
                    return (
                      <button
                        type="button"
                        aria-label={`slide carousel to index ${index + 1}`}
                        key={`selector-${index}`}
                        className={cn([
                          'border-4 border-transparent scale-75 h-8 w-8 rounded-full',
                          // card.info.colors === virtualCardColor.oxFormat
                          arraysAreEqual(color.oxFormat, virtualCardColor.oxFormat) &&
                            'border-white shadow-lg scale-125',
                        ])}
                        style={{ background: color.class }}
                        onClick={() => {
                          api?.scrollTo(index);
                          dispatch(setVirtualCardColor(color));
                        }}
                      />
                    );
                  })}
              </div>

              <DashboardCardLayout>
                <Input
                  id="label"
                  label="Enter a card label"
                  placeholder="e.g General, Travel, Shopping"
                  {...getInputProps('label')}
                />
              </DashboardCardLayout>

              <Button
                type="submit"
                variant="purple"
                className="w-full"
                isLoading={isLoadingUpdateCard}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <Loader />
      )}
    </SubRouteLayout>
  );
};

export default EditColor;
