import Flag from 'components/flag';
import Modal, { ModalProps } from 'components/modal';
import { X } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useGetConfigurations } from 'services/configurations';
import { useAppDispatch } from 'store';
import { setVaultPlan } from 'store/slices/vault/vaultSlice';
import { Configuration, VaultPlansValue } from 'types/configuration';
import { formatAmount } from 'utils/numberFormat';

import DashboardCardLayout from '../components/dashboardCardLayout';

const VaultPlansModal = ({ ...props }: ModalProps) => {

  const dispatch = useAppDispatch();

  const handleClick = (plan: { duration: number; rate: number; label: string; name: string }) => {
    dispatch(setVaultPlan(plan));
    props.close();
  };

  const params = useParams()
  const { data: configurations } = useGetConfigurations()

  const vaultPlans = configurations?.data.find((item): item is Configuration<VaultPlansValue> => item.name === 'vault_plans')?.value

  const plan = vaultPlans?.find((item) => item.code === params.id)

  return (
    <Modal {...props} className="py-7 px-5  xl:w-full xl:max-w-xl">
      <div className="flex justify-between">
        <p className="text-lg xl:text-2xl font-medium">
          Select Vault Plan
        </p>
        <X onClick={props.close} className="cursor-pointer" />
      </div>

      <div >
        {plan && plan.plans.map((item) => (
          <button onClick={() => {
            handleClick(item)
          }}
            key={item.name}
            className='text-start'
          >
            <DashboardCardLayout key={item.name} className='w-full max-w-full xl:w-full flex items-start justify-between mt-3 md:mt-6'>
              <div className='flex items-start space-x-3 pr-5'>
                <Flag code={plan.code} className='w-5 h-5 md:w-10 md:h-10' />
                <div className='md:mt-1'>
                  <p className='text-sm md:text-lg capitalize'>{item.name}</p>
                  <p className='mt-2 text-[0.626rem] md:text-sm'>If you save {plan.name.toUpperCase()} 500,000 on an interest rate of <span className='font-bold'>{item.rate}%</span> after <span className='font-bold'>{item.duration} days</span>, you will receive a total of {plan.name.toUpperCase()} {formatAmount((500000 + (500000 * (item.rate / 100))).toString())}</p>
                </div>
              </div>
              <div className='bg-platnova-secondary rounded-full text-platnova-purple text-xs md:text-base font-medium px-3 py-2 md:mt-1'>
                {item.rate}%
              </div>
            </DashboardCardLayout>
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default VaultPlansModal;
