import Atm from 'assets/icons/atm.png';
import Limit from 'assets/icons/limit.png';
import Mobile from 'assets/icons/mobile.png';
import Paint from 'assets/icons/paint.png';
import Pos from 'assets/icons/pos.png';
import Shield from 'assets/icons/shield.png';
import Web from 'assets/icons/web.png';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Loader from 'components/Loader';
import { Switch } from 'components/ui/switch';
import { ChevronLeft, Loader2 } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EDIT_CARD_COLOR_ROUTE, EDIT_CARD_LIMITS_ROUTE } from 'routes/path';
import { useGetCards, useResendCardPin, useUpdateCard } from 'services/cards';

import DashboardCardLayout from '../components/dashboardCardLayout';
import SubRouteLayout from '../components/subRouteLayout';

const CardControl = () => {
  const navigate = useNavigate();
  const { mutate: updateCard, isPending: isLoadingUpdateCard } = useUpdateCard();
  const { id } = useParams();

  const handleSwitchChange = async (name: string, checked: boolean) => {
    try {
      updateCard(
        {
          id: id as string,
          channel: {
            [name]: checked,
          },
        },
        {
          onSuccess: () => {
            notifySuccess(`${name} channel ${checked ? 'enabled' : 'disabled'}`);
          },
          onError: (err) => {
            handleErrors(err);
          },
        }
      );
    } catch (err) {
      handleErrors(err);
    }
  };

  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();

  const { mutate: resendCardPin, isPending: isLoadingResendCardPin } = useResendCardPin();

  const card = getCardsResponse?.data.find((card) => card.id === id);

  return (
    <SubRouteLayout header="Card control">
      {card && !isLoadingGetCardsResponse ? (
        <div className="pb-20 xl:-ml-[4.688rem]">
          <div className="xl:flex items-start xl:space-x-11">
            <button
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="space-y-10">
              <div className="space-y-3">
                <p className="text-lg md:text-2xl font-medium capitalize">
                  {card.info.type} card settings
                </p>
                <p className="text-xs md:text-base text-black/70">
                  Update settings of card ending in {card.info.masked_pan.slice(-4)}
                </p>
              </div>

              <div className="space-y-5 w-full">
                <p className="text-sm md:text-xl font-medium">Customize</p>
                <Link to={EDIT_CARD_COLOR_ROUTE(id as string)}>
                  <DashboardCardLayout className="flex space-x-6 items-center">
                    <img src={Paint} alt="color" />
                    <div>
                      <p className="text-sm md:text-lg font-medium">Label & Color</p>
                      <p className="text-xs md:text-lg text-grey-text">
                        Change your card label and color
                      </p>
                    </div>
                  </DashboardCardLayout>
                </Link>
              </div>

              {card.info.type === 'physical' && (
                <div className="space-y-5">
                  <p className="text-sm md:text-xl font-medium">Limit</p>
                  <Link to={EDIT_CARD_LIMITS_ROUTE(id as string)}>
                    <DashboardCardLayout className="flex space-x-6 items-center">
                      <img src={Limit} alt="limit" />
                      <div>
                        <p className="text-sm md:text-lg font-medium">Spending Limit</p>
                        <p className="text-xs md:text-lg text-grey-text">Spend/ Withdraw limit</p>
                      </div>
                    </DashboardCardLayout>
                  </Link>
                </div>
              )}

              {card.info.type === 'physical' && (
                <div className="space-y-5">
                  <p className="text-sm md:text-xl font-medium">Security</p>
                  <button
                    className="cursor-pointer text-start disabled:cursor-not-allowed w-full"
                    disabled={isLoadingResendCardPin}
                    onClick={() => {
                      try {
                        resendCardPin(id as string, {
                          onSuccess: () => {
                            notifySuccess('Pin sent successfully');
                          },
                        });
                      } catch (err) {
                        handleErrors(err);
                      }
                    }}
                  >
                    <DashboardCardLayout className="flex justify-between items-center w-full">
                      <div className="flex space-x-6 items-center">
                        <img src={Shield} alt="Shield" />
                        <div>
                          <p className="text-sm md:text-lg font-medium">Resend Default PIN</p>
                          <p className="text-xs md:text-lg text-grey-text">
                            Send the default PIN via email and push
                          </p>
                        </div>
                      </div>
                      {isLoadingResendCardPin && <Loader2 className="animate-spin" />}
                    </DashboardCardLayout>
                  </button>
                  <DashboardCardLayout className="flex justify-between items-center">
                    <div className="flex space-x-6 items-center">
                      <img src={Atm} alt="Atm" />
                      <div>
                        <p className="text-sm md:text-lg font-medium">ATM</p>
                        <p className="text-xs md:text-lg text-grey-text">
                          Allow this card to work on ATMs
                        </p>
                      </div>
                    </div>
                    {isLoadingUpdateCard ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <Switch
                        checked={card.info.control.channels?.atm}
                        onClick={() => {
                          handleSwitchChange('atm', !card.info.control.channels?.atm);
                        }}
                      />
                    )}
                  </DashboardCardLayout>
                  <DashboardCardLayout className="flex justify-between items-center">
                    <div className="flex space-x-6 items-center">
                      <img src={Pos} alt="Pos" />
                      <div>
                        <p className="text-sm md:text-lg font-medium">POS</p>
                        <p className="text-xs md:text-lg text-grey-text">
                          Allow this card to work on POS machines
                        </p>
                      </div>
                    </div>
                    {isLoadingUpdateCard ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <Switch
                        checked={card.info.control.channels?.pos}
                        onClick={() => {
                          handleSwitchChange('pos', !card.info.control.channels?.pos);
                        }}
                      />
                    )}
                  </DashboardCardLayout>
                  <DashboardCardLayout className="flex justify-between items-center">
                    <div className="flex space-x-6 items-center">
                      <img src={Web} alt="Web" />
                      <div>
                        <p className="text-sm md:text-lg font-medium">Web</p>
                        <p className="text-xs md:text-lg text-grey-text">
                          Allow this card to work on online stores
                        </p>
                      </div>
                    </div>
                    {isLoadingUpdateCard ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <Switch
                        checked={card.info.control.channels?.web}
                        onClick={() => {
                          handleSwitchChange('web', !card.info.control.channels?.web);
                        }}
                      />
                    )}
                  </DashboardCardLayout>
                  <DashboardCardLayout className="flex justify-between items-center">
                    <div className="flex space-x-6 items-center">
                      <img src={Mobile} alt="Mobile" />
                      <div>
                        <p className="text-sm md:text-lg font-medium">Mobile</p>
                        <p className="text-xs md:text-lg text-grey-text">
                          Allow this card to work on mobile channels
                        </p>
                      </div>
                    </div>
                    {isLoadingUpdateCard ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <Switch
                        checked={card.info.control.channels?.mobile}
                        onClick={() => {
                          handleSwitchChange('mobile', !card.info.control.channels?.mobile);
                        }}
                      />
                    )}
                  </DashboardCardLayout>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </SubRouteLayout>
  );
};

export default CardControl;
