import Flag from 'components/flag'
import { ChevronLeft } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetConfigurations } from 'services/configurations'
import { useAppDispatch, useAppSelector } from 'store'
import { VAULT_SLICE_REDUCER_PATH } from 'store/slices/vault/constants'
import { setCreateVaultStage, setVaultPlan } from 'store/slices/vault/vaultSlice'
import { Configuration, VaultPlansValue } from 'types/configuration'
import { formatAmount } from 'utils/numberFormat'

import CreateVaultPlan from './createVaultPlan'
import DashboardCardLayout from '../components/dashboardCardLayout'
import SubRouteLayout from '../components/subRouteLayout'

const VaultPlans = () => {
  const navigate = useNavigate()

  const { stage } = useAppSelector(state => state[VAULT_SLICE_REDUCER_PATH])

  const dispatch = useAppDispatch()

  const params = useParams()
  const { data: configurations } = useGetConfigurations()

  const vaultPlans = configurations?.data.find((item): item is Configuration<VaultPlansValue> => item.name === 'vault_plans')?.value

  const plan = vaultPlans?.find((item) => item.code === params.id)

  return (
    <div>
      {stage === 'type' &&
        <SubRouteLayout header='Vault'>
          <div className="pb-20 xl:-ml-[4.688rem]">
            <div className="xl:flex items-start xl:space-x-11">
              <button
                onClick={() => navigate(-1)}
                className="flex space-x-1.5 xl:space-x-3 items-center"
              >
                <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
                <span className="text-xs xl:text-base">Back</span>
              </button>

              <div className="space-y-10 xl:w-[37.5rem]">
                <div className="space-y-3">
                  <p className="text-lg md:text-2xl font-medium capitalize mt-10 md:mt-0">
                    Choose a plan
                  </p>
                  <p className="text-xs md:text-base text-black/70">
                    Choose a saving plan and earn interest in {plan?.name.toUpperCase()} today
                  </p>

                  <div className=''>
                    {plan && plan.plans.map((item) => (
                      <button onClick={() => {
                        dispatch(setVaultPlan(item))
                        dispatch(setCreateVaultStage({ stage: 'input' }))
                      }}
                        key={item.name}
                        className='text-start'
                      >
                        <DashboardCardLayout key={item.name} className='w-full max-w-full flex items-start justify-between mt-3 md:mt-6'>
                          <div className='flex items-start space-x-3 pr-5'>
                            <Flag code={plan.code} className='w-5 h-5 md:w-10 md:h-10' />
                            <div className='md:mt-1'>
                              <p className='text-sm md:text-lg capitalize'>{item.name}</p>
                              <p className='mt-2 text-[0.626rem] md:text-sm'>If you save {plan.name.toUpperCase()} 500,000 on an interest rate of <span className='font-bold'>{item.rate}%</span> after <span className='font-bold'>{item.duration} days</span>, you will receive a total of {plan.name.toUpperCase()} {formatAmount((500000 + (500000 * (item.rate / 100))).toString())}</p>
                            </div>
                          </div>
                          <div className='bg-platnova-secondary rounded-full text-platnova-purple text-xs md:text-base font-medium px-3 py-2 md:mt-1'>
                            {item.rate}%
                          </div>
                        </DashboardCardLayout>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SubRouteLayout>
      }
      {stage === 'input' && <CreateVaultPlan />}
    </div>
  )
}

export default VaultPlans