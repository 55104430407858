import { Carousel } from 'components/ui/carousel';

import EditColor from './editColor';

const EditColorContainer = () => {
  return (
    <div className="">
      <Carousel>
        <EditColor />
      </Carousel>
    </div>
  );
};

export default EditColorContainer;
