import http from 'services/http';
import {
  CreateVirtualCardInput,
  GeneralApiResponse,
  // LinkCardInput,
  OrderPhysicalCardInput,
} from 'types';
import {
  Card,
  DepositCardInput,
  LinkCardInput,
  RevealCardResponse,
  UpdateCardInput,
} from 'types/card';

export const getCards = async (): Promise<GeneralApiResponse<Card[]>> => {
  const res = await http.get('/cards');
  return res.data;
};

export const getCardEvents = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.get(`/cards/${id}/events`);
  return res.data;
};

export const revealCard = async (id: string): Promise<GeneralApiResponse<RevealCardResponse>> => {
  const res = await http.get(`/cards/${id}/reveal`);
  return res.data;
};

export const resendCardPin = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.get(`/cards/${id}/resend_pin`);
  return res.data;
};

export const postOrderPhysicalCard = async (
  data: OrderPhysicalCardInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/cards/order', data);
  return res.data;
};

export const postCreateVirtualCard = async (
  data: CreateVirtualCardInput
): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/cards', data);
  return res.data;
};

export const freezeCard = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.put(`/cards/${id}/block`);
  return res.data;
};

export const unfreezeCard = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.put(`/cards/${id}/unblock`);
  return res.data;
};

export const deleteCard = async (id: string): Promise<GeneralApiResponse<object>> => {
  const res = await http.delete(`/cards/${id}`);
  return res.data;
};

export const updateCard = async (payload: UpdateCardInput): Promise<GeneralApiResponse<object>> => {
  const { id: _, ...realPayload } = payload;
  const res = await http.patch(`/cards/${payload.id}`, realPayload);
  return res.data;
};

export const postDepositCard = async (
  data: DepositCardInput
): Promise<GeneralApiResponse<string>> => {
  const res = await http.post(`/cards/${data.id}/deposit`, {
    currency: data.currency,
    amount: data.amount,
  });
  return res.data;
};

export const postWithdrawCard = async (
  data: DepositCardInput
): Promise<GeneralApiResponse<string>> => {
  const res = await http.post(`/cards/${data.id}/withdraw`, {
    currency: data.currency,
    amount: data.amount,
  });
  return res.data;
};

export const postLinkCard = async (data: LinkCardInput): Promise<GeneralApiResponse<object>> => {
  const res = await http.post('/cards/link', data);
  return res.data;
};
