import { combineReducers } from '@reduxjs/toolkit';

import { accountsReducer } from './slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH } from './slices/account/constants';
import { cardBrandReducer } from './slices/cards/cardsSlice';
import { CARD_SLICE_REDUCER_PATH } from './slices/cards/constants';
import { FORGOT_PASSWORD_SLICE_REDUCER_PATH } from './slices/forgotPassword/constants';
import { forgotPasswordReducer } from './slices/forgotPassword/forgotPasswordSlice';
import { LAYOUT_SLICE_REDUCER_PATH } from './slices/layout/constants';
import { layoutReducer } from './slices/layout/layoutSlice';
import { LOGIN_SLICE_REDUCER_PATH } from './slices/login/constants';
import { loginSliceReducer } from './slices/login/loginSlice';
import { REFILL_SLICE_REDUCER_PATH } from './slices/refill/constants';
import { refillReducer } from './slices/refill/refillSlice';
import { REGISTER_SLICE_REDUCER_PATH } from './slices/register/constants';
import { registerSliceReducer } from './slices/register/registerSlice';
import { VAULT_SLICE_REDUCER_PATH } from './slices/vault/constants';
import { vaultReducer } from './slices/vault/vaultSlice';
import { VERIFICATION_SLICE_REDUCER_PATH } from './slices/verification/constants';
import { verificationReducer } from './slices/verification/verificationSlice';
import { WALLET_SLICE_REDUCER_PATH } from './slices/wallet/constants';
import { walletReducer } from './slices/wallet/walletSlice';

export const rootReducer = combineReducers({
  [REGISTER_SLICE_REDUCER_PATH]: registerSliceReducer,
  [LOGIN_SLICE_REDUCER_PATH]: loginSliceReducer,
  [LAYOUT_SLICE_REDUCER_PATH]: layoutReducer,
  [FORGOT_PASSWORD_SLICE_REDUCER_PATH]: forgotPasswordReducer,
  [ACCOUNTS_SLICE_REDUCER_PATH]: accountsReducer,
  [WALLET_SLICE_REDUCER_PATH]: walletReducer,
  [VERIFICATION_SLICE_REDUCER_PATH]: verificationReducer,
  [CARD_SLICE_REDUCER_PATH]: cardBrandReducer,
  [REFILL_SLICE_REDUCER_PATH]: refillReducer,
  [VAULT_SLICE_REDUCER_PATH]: vaultReducer
});
