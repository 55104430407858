import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setLinkCardStage } from 'store/slices/cards/cardsSlice';
import { CARD_SLICE_REDUCER_PATH } from 'store/slices/cards/constants';

import LinkCardControlStage from './components/linkCardControlStage';
import LinkCardInputStage from './components/linkCardInputStage';
import SubRouteLayout from '../components/subRouteLayout';

const LinkPhysicalCard = () => {
  const navigate = useNavigate();
  const { linkCardStage } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const dispatch = useAppDispatch();

  return (
    <SubRouteLayout header="Link platnova card">
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => {
              linkCardStage === 'input' && navigate(-1);
              linkCardStage === 'control' && dispatch(setLinkCardStage({ linkCardStage: 'input' }));
            }}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          {linkCardStage === 'input' && <LinkCardInputStage />}
          {linkCardStage === 'control' && <LinkCardControlStage />}
        </div>
      </div>
    </SubRouteLayout>
  );
};

export default LinkPhysicalCard;
