export const GET_GIFTCARDS_QUERY_KEY = 'getGiftcards' as const;
export const GET_FEATURED_GIFTCARDS_QUERY_KEY = 'getGiftcards' as const;
export const GET_AIRTIMES_QUERY_KEY = 'getAirtimes' as const;

export type Airtime = {
  id: string
  name: string
  slug: string
  type: string
  vas_meta: {
    countries: MetaCountry[]
  }
}

export type MetaCountry = {
  currency: string
  denomination: object
  denomination_type: 'RANGE' | 'FIXED',
  desciption: string
  dial_code: string
  iso_code: string
  limit: number
  logo: string
  name: string
  provider: number
  provider_id: string
  categories?: DataCategory[]
}

export type DataCategory = {
  name: string
  bundle_code: string
  amount: string
  is_amount_fixed: boolean
}