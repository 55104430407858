import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const notifyError = (message: string) => {
  toast.error(message);
};

export const handleErrors = (error: unknown) => {
  if (error instanceof AxiosError) {
    if (typeof error.message === 'string') {
      toast.error(error.message);

      return;
    }
  }

  if (typeof error === 'object' && error) {
    if ('data' in error) {
      const errorobj = error.data;

      if (typeof error.data === 'string') {
        toast.error(error.data);

        return;
      }

      if (
        errorobj &&
        typeof errorobj === 'object' &&
        'data' in errorobj &&
        errorobj.data &&
        typeof errorobj.data === 'object' &&
        'error' in errorobj.data &&
        typeof errorobj.data.error === 'string'
      ) {
        if (errorobj.data.error === "Can't validate pin") {
          toast.error('Incorrect pin');

          return;
        }
        toast.error(errorobj.data.error);

        return;
      }

      if (
        errorobj &&
        typeof errorobj === 'object' &&
        'data' in errorobj &&
        errorobj.data &&
        typeof errorobj.data === 'object' &&
        'data' in errorobj.data &&
        errorobj.data.data &&
        typeof errorobj.data.data === 'object' &&
        'message' in errorobj.data.data &&
        errorobj.data.data &&
        errorobj.data.data.message &&
        typeof errorobj.data.data.message === 'string'
      ) {
        toast.error(errorobj.data.data.message);

        return;
      }

      if (
        errorobj &&
        typeof errorobj === 'object' &&
        'data' in errorobj &&
        errorobj.data &&
        typeof errorobj.data === 'object' &&
        'message' in errorobj.data &&
        typeof errorobj.data.message === 'string'
      ) {
        toast.error(errorobj.data.message);

        return;
      }

      if (
        errorobj &&
        typeof errorobj === 'object' &&
        'message' in errorobj &&
        typeof errorobj.message === 'string'
      ) {
        toast.error(errorobj.message);
        return;
      }
    }
  }
};
