import Button from 'components/Buttons/Button';
import useDisclosure from 'hooks/useDisclosure';
import { DEPOSIT_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/deposit';
import { SEND_PAYMENT_METHOD_KEY } from 'lib/constants/accounts/send';
import {
  useGetActiveRecipientWallet,
  useGetActiveWallet,
  useGetPaymentMethods,
  useGetSendPaymentMethods,
} from 'lib/utils';
import { useEffect } from 'react';
import { useGetCards } from 'services/cards';
import { useAppDispatch, useAppSelector } from 'store';
import {
  setDuration,
  setPaymentMethod,
  setSendPaymentMethod,
} from 'store/slices/account/accountsSlice';
import { ACCOUNTS_SLICE_REDUCER_PATH } from 'store/slices/account/constants';
import { CARD_INDEX, CARD_SLICE_REDUCER_PATH, virtualCardList } from 'store/slices/cards/constants';

import PaymentMethodModal from './paymentMethodModal';

const PaymentMethod = ({ type = 'deposit' }: { type: 'send' | 'deposit' | 'deposit-card' }) => {
  const {
    [DEPOSIT_PAYMENT_METHOD_KEY]: paymentMethod,
    [SEND_PAYMENT_METHOD_KEY]: sendPaymentMethod,
  } = useAppSelector((state) => state[ACCOUNTS_SLICE_REDUCER_PATH]);

  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();

  const { [CARD_INDEX]: cardIndex } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const activeWallet = useGetActiveWallet();
  const activeRecipientWallet = useGetActiveRecipientWallet();

  const dispatch = useAppDispatch();

  const sendPaymentMethods = useGetSendPaymentMethods();

  const activeSendPaymentMethod = sendPaymentMethods?.find((x) => x.id === sendPaymentMethod);

  useEffect(() => {
    dispatch(setPaymentMethod(''));
    dispatch(setDuration(''));
    if (!activeSendPaymentMethod) {
      dispatch(setSendPaymentMethod(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeWallet, activeRecipientWallet]);

  const {
    isOpen: isChangePaymentMethodModalOpen,
    open: openPaymentMethodModal,
    close: closePaymentMethodModal,
  } = useDisclosure();

  const paymentMethods = useGetPaymentMethods();

  // console.log(sendPaymentMethods)

  const activePaymentMethod = paymentMethods?.find((x) => x.id === paymentMethod);

  const arraysAreEqual = (a: string[], b: string[]) =>
    a.length === b.length && a.every((value, index) => value === b[index]);

  const getCardColor = () => {
    if (getCardsResponse?.data[cardIndex].info.type === 'virtual') {
      const foundCard = virtualCardList.find((x) =>
        arraysAreEqual(x.oxFormat, getCardsResponse.data[cardIndex].info.colors)
      );

      if (foundCard) {
        return foundCard;
      }
    }
    return null;
  };

  const cardColor = getCardColor();

  return (
    <div>
      <div className="bg-primary-grey p-2 xl:p-5 flex justify-between">
        {activePaymentMethod && type === 'deposit' && (
          <div>
            <div className="flex justify-between">
              <div className="flex items-center space-x-3 xl:space-x-6">
                <img
                  src={`https://res.cloudinary.com/platnova/assets/payment_methods/${activePaymentMethod.id}`}
                  alt="gg"
                  className="w-6 h-6 xl:w-10 xl:h-10"
                />
                <div className="flex flex-col items-start text-start">
                  <p className="text-sm xl:text-base">{activePaymentMethod.name}</p>
                  <p className="text-[0.625rem]] xl:text-xs text-grey-text">
                    {activePaymentMethod.description}
                  </p>
                </div>
              </div>
            </div>
            {activePaymentMethod.channel.length > 0 && (
              <div className="border-t mt-5 pt-5">
                <p className="text-start text-[0.625rem] text-grey-text">Supports</p>
                <div className="flex mt-3 items-center space-x-3">
                  {activePaymentMethod.channel.map((item, idx) => (
                    <img
                      src={`https://res.cloudinary.com/platnova/assets/payment_methods/${item}`}
                      alt="gg"
                      key={idx}
                      className="h-3.5 xl:h-5 w-auto"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {activeSendPaymentMethod && type === 'send' && (
          <div>
            <div className="flex justify-between">
              <div className="flex items-center space-x-3 xl:space-x-6">
                <img
                  src={`https://res.cloudinary.com/platnova/assets/payment_methods/${activeSendPaymentMethod.id}`}
                  alt="gg"
                  className="w-6 h-6 xl:w-10 xl:h-10"
                />
                <div className="flex flex-col items-start text-start">
                  <p className="text-sm xl:text-base">{activeSendPaymentMethod.name}</p>
                  <p className="text-[0.625rem]] xl:text-xs text-grey-text">
                    {activeSendPaymentMethod.description}
                  </p>
                </div>
              </div>
            </div>
            {activeSendPaymentMethod.channel.length > 0 && (
              <div className="border-t mt-5 pt-5">
                <p className="text-start text-[0.625rem] text-grey-text">Supports</p>
                <div className="flex mt-3 items-center space-x-3">
                  {activeSendPaymentMethod.channel.map((item, idx) => (
                    <img
                      src={`https://res.cloudinary.com/platnova/assets/payment_methods/${item}`}
                      alt="gg"
                      key={idx}
                      className="h-3.5 xl:h-5 w-auto"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {getCardsResponse && !isLoadingGetCardsResponse && type === 'deposit-card' && (
          <div>
            <div className="flex justify-between">
              <div className="flex items-center space-x-3 xl:space-x-6">
                <div
                  className="w-6 h-6 xl:w-10 xl:h-10 rounded-full"
                  style={{ background: cardColor?.class }}
                />
                <div className="flex flex-col items-start text-start">
                  <p className="text-xs xl:text-sm">
                    {getCardsResponse.data[cardIndex].info.name_on_card}
                  </p>
                  <p className="text-[0.625rem]] xl:text-xs text-grey-text">
                    card ending in {getCardsResponse.data[cardIndex].info.last4}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {((type === 'deposit' && !paymentMethod) || (type === 'send' && !sendPaymentMethod)) && (
          <div className="flex items-center space-x-3 xl:space-x-9">
            <div className="p-1 xl:p-1 border-2 border-grey-text/50 rounded-full">
              <div className="p-1 xl:p-2 bg-grey-text/50 rounded-full"></div>
            </div>
            <div>
              <p className="text-sm xl:text-lg font-medium">Payment method</p>
              <p className="text-grey-text text-xs xl:text-base">Select a payment method</p>
            </div>
          </div>
        )}

        <Button
          variant="secondary"
          type="button"
          onClick={openPaymentMethodModal}
          className="py-1.5 px-2 xl:py-3 xl:px-5 h-max xl:ml-5 shadow-none my-auto"
        >
          Change
        </Button>
      </div>

      <PaymentMethodModal
        type={type}
        isOpen={isChangePaymentMethodModalOpen}
        close={closePaymentMethodModal}
      />
    </div>
  );
};

export default PaymentMethod;
