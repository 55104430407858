import Flag from 'components/flag'
import { ChevronDown } from 'lucide-react'
import { Link } from 'react-router-dom'
import { VAULT_PLANS_ROUTE } from 'routes/path'
import { useGetConfigurations } from 'services/configurations'
import { Configuration, VaultPlansValue } from 'types/configuration'

const SelectVault = () => {
  const { data: configurations, isPending } = useGetConfigurations()

  const vaultPlans = configurations?.data.find((item): item is Configuration<VaultPlansValue> => item.name === 'vault_plans')?.value

  return (
    <div className='text-start w-full xl:w-auto'>
      <p className='text-base md:text-2xl font-medium'>Select a currency and start saving</p>
      <p className='text-grey-text text-xs md:text-xl mt-5'>Get started with Platnova Vault and earn interest today </p>

      <div className='grid grid-cols-2 md:grid-cols-3 gap-4 mt-14'>
        {vaultPlans && !isPending && vaultPlans.map((plan, idx) => (
          <Link to={VAULT_PLANS_ROUTE(plan.code)} key={idx} >
            <div className='relative '>
              <img className='w-full' src={`https://res.cloudinary.com/platnova/image/upload/v1720032205/assets/vaults/${plan.code}.png`} alt={plan.code} />
              <div className='bg-white/80 p-2 px-3 rounded-full absolute left-[18%] top-[38%] h-max w-max flex space-x-2 items-center'>
                <Flag code={plan.code} className='w-5 h-5' />
                <p className='text-base'>{plan.name.toUpperCase()}</p>
                <ChevronDown />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default SelectVault