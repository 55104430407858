import { useFormik } from 'formik';
import { REGISTER_EMAIL_KEY, REGISTER_REFERRAL_CODE_KEY } from 'lib/constants/auth/register';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ACCOUNT_VERIFICATION_ROUTE } from 'routes/path';
import { useRegisterAccount } from 'services/auth/register';
import { useAppDispatch, useAppSelector } from 'store';
import { REGISTER_SLICE_REDUCER_PATH } from 'store/slices/register/constants';
import { setReferralCode, setRegisterEmail } from 'store/slices/register/registerSlice';
import { object, string } from 'yup';

export default function useRegisterForm() {
  const { mutate, error, isError, isPending } = useRegisterAccount();
  const navigate = useNavigate();
  const { [REGISTER_EMAIL_KEY]: storeEmail, [REGISTER_REFERRAL_CODE_KEY]: storeCode } = useAppSelector(
    (state) => state[REGISTER_SLICE_REDUCER_PATH]
  );

  const [searchParams] = useSearchParams();
  const ref = searchParams.get('code')

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      [REGISTER_EMAIL_KEY]: storeEmail || '',
      [REGISTER_REFERRAL_CODE_KEY]: ref || storeCode || ''
    },
    onSubmit: (values) => {
      mutate(values, {
        onSuccess: () => {
          dispatch(setRegisterEmail(values[REGISTER_EMAIL_KEY]));
          dispatch(setReferralCode(values[REGISTER_REFERRAL_CODE_KEY]))
          navigate(ACCOUNT_VERIFICATION_ROUTE);
        },
      });
    },
    validationSchema: object().shape({
      [REGISTER_EMAIL_KEY]: string()
        .email('Enter a valid email address')
        .required('Email cannot be empty'),
      [REGISTER_REFERRAL_CODE_KEY]: string()
    }),
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    getInputProps,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    registerError: error,
    isError: isError,
    isPending: isPending,
  });
}
