import Atm from 'assets/icons/atm.png';
import Mobile from 'assets/icons/mobile.png';
import Pos from 'assets/icons/pos.png';
import Web from 'assets/icons/web.png';
import Button from 'components/Buttons/Button';
import Modal, { ModalProps } from 'components/modal';
import { Switch } from 'components/ui/switch';
import { X } from 'lucide-react';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';

const ChannelsModal = (props: ModalProps) => {
  return (
    <Modal
      {...props}
      className="p-8 w-full max-w-xl md:max-w-2xl md:w-full scroll-component pb-24 xl:pb-8 space-y-6"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium capitalize">Channels</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>
      <p className="text-xs md:text-base text-black/70">
        Enable the channels/modes where you want your card to be used
      </p>
      <form className="space-y-6">
        <DashboardCardLayout className="flex justify-between items-center">
          <div className="flex space-x-6 items-center">
            <img src={Atm} alt="Atm" />
            <div>
              <p className="text-lg font-medium">ATM</p>
              <p className="text-lg text-grey-text">Allow this card to work on ATMs</p>
            </div>
          </div>
          <Switch />
        </DashboardCardLayout>
        <DashboardCardLayout className="flex justify-between items-center">
          <div className="flex space-x-6 items-center">
            <img src={Pos} alt="Pos" />
            <div>
              <p className="text-lg font-medium">POS</p>
              <p className="text-lg text-grey-text">Allow this card to work on POS machines</p>
            </div>
          </div>
          <Switch />
        </DashboardCardLayout>
        <DashboardCardLayout className="flex justify-between items-center">
          <div className="flex space-x-6 items-center">
            <img src={Web} alt="Web" />
            <div>
              <p className="text-lg font-medium">Web</p>
              <p className="text-lg text-grey-text">Allow this card to work on online stores</p>
            </div>
          </div>
          <Switch />
        </DashboardCardLayout>
        <DashboardCardLayout className="flex justify-between items-center">
          <div className="flex space-x-6 items-center">
            <img src={Mobile} alt="Mobile" />
            <div>
              <p className="text-lg font-medium">Mobile</p>
              <p className="text-lg text-grey-text">Allow this card to work on mobile channels</p>
            </div>
          </div>
          <Switch />
        </DashboardCardLayout>
        <Button type="submit" variant="purple" className="w-full">
          Continue
        </Button>
      </form>
    </Modal>
  );
};

export default ChannelsModal;
