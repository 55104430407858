import { notifySuccess } from 'components/error/notifySuccess';
import { useFormik } from 'formik';
import { useGetCreateCardLimits } from 'lib/utils';
import { useCreateVirtualCard } from 'services/cards';
import {
  CREATE_VIRTUAL_CARD_AMOUNT_KEY,
  CREATE_VIRTUAL_CARD_BRAND_KEY,
  CREATE_VIRTUAL_CARD_COLORS_KEY,
  CREATE_VIRTUAL_CARD_CURRENCY_KEY,
  CREATE_VIRTUAL_CARD_PROVIDER_KEY,
} from 'services/cards/constants';
import { useGetWallets } from 'services/wallet';
import { useAppDispatch, useAppSelector } from 'store';
import { setCreateVirtualCardStage } from 'store/slices/cards/cardsSlice';
import { CARD_SLICE_REDUCER_PATH, VIRTUAL_CARD_COLOR_KEY } from 'store/slices/cards/constants';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { array, number, object, string } from 'yup';

export const useCreateVirtualCardForm = () => {
  const { data: wallets } = useGetWallets();
  const {
    mutate: createVirtualCard,
    isPending: isLoadingCreateVirtualCard,
    error: createVirtualCardError,
    isError: isCreateVirtualCardError,
  } = useCreateVirtualCard();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);

  const dispatch = useAppDispatch();

  const limits = useGetCreateCardLimits();

  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const { [VIRTUAL_CARD_COLOR_KEY]: virtualCardColor } = useAppSelector(
    (state) => state[CARD_SLICE_REDUCER_PATH]
  );

  const initialValues = {
    [CREATE_VIRTUAL_CARD_CURRENCY_KEY]: 'usd',
    [CREATE_VIRTUAL_CARD_COLORS_KEY]: virtualCardColor.colors,
    [CREATE_VIRTUAL_CARD_AMOUNT_KEY]: '',
    [CREATE_VIRTUAL_CARD_BRAND_KEY]: 'visa',
    [CREATE_VIRTUAL_CARD_PROVIDER_KEY]: 'hostcap',
  };

  const validationSchema = object().shape({
    [CREATE_VIRTUAL_CARD_CURRENCY_KEY]: string().required(),
    [CREATE_VIRTUAL_CARD_COLORS_KEY]: array().required(),
    [CREATE_VIRTUAL_CARD_AMOUNT_KEY]: number()
      .transform((value, originalValue) => {
        return Number(removeNonDigit(String(originalValue)));
      })
      .required()
      .min(
        limits?.min ?? 0,
        `Minimum ${formatAmount(String(limits?.min))} ${activeWallet?.type.toUpperCase()}`
      )
      .max(
        limits?.max ?? 0,
        `Maximum ${formatAmount(String(limits?.max))} ${activeWallet?.type.toUpperCase()}`
      ),
    [CREATE_VIRTUAL_CARD_BRAND_KEY]: string().required(),
    [CREATE_VIRTUAL_CARD_PROVIDER_KEY]: string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const newValues = {
        ...values,
        [CREATE_VIRTUAL_CARD_AMOUNT_KEY]: Number(
          removeNonDigit(String(values[CREATE_VIRTUAL_CARD_AMOUNT_KEY]))
        ),
      };
      createVirtualCard(newValues, {
        onSuccess: () => {
          notifySuccess('Success');
          formik.resetForm();
          dispatch(setCreateVirtualCardStage({ virtualStage: 'success' }));
        },
      });
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldHelpers(id),
      ...formik.getFieldMeta(id),
      ...formik.getFieldProps(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    submit: formik.handleSubmit,
    getInputProps,
    values: formik.values,
    errors: formik.errors,
    isLoadingCreateVirtualCard,
    isCreateVirtualCardError,
    createVirtualCardError,
    setFieldValue: formik.setFieldValue,
  });
};
