import { createElement } from 'react';
import {
  CARD_CONTROL_ROUTE,
  CARDS_ROUTE,
  CREATE_PHYSICAL_CARD_ROUTE,
  CREATE_VIRTUAL_CARD_ROUTE,
  DEPOSIT_CARD_ROUTE,
  EDIT_CARD_COLOR_ROUTE,
  EDIT_CARD_LIMITS_ROUTE,
  LINK_PHYSICAL_CARD_ROUTE,
  WITHDRAW_CARD_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import CardControl from 'views/dashboard/cards/cardControl';
import Cards from 'views/dashboard/cards/cards';
import EditCardLimits from 'views/dashboard/cards/components/editCardLimits';
import EditColorContainer from 'views/dashboard/cards/components/editColorContainer';
import CreatePhysicalCard from 'views/dashboard/cards/createPhysicalCard';
import CreateVirtualCard from 'views/dashboard/cards/createVirtualCard';
import DepositCard from 'views/dashboard/cards/depositCard';
import LinkPhysicalCard from 'views/dashboard/cards/linkPhysicalCard';
import WithdrawCard from 'views/dashboard/cards/withdrawCard';

export const cardsRoutes: AppRouteConfig[] = [
  {
    path: CARDS_ROUTE,
    element: createElement(Cards),
    restricted: true,
    showNav: true,
    showSideBar: true,
    isKycLimited: true,
  },
  {
    path: CREATE_PHYSICAL_CARD_ROUTE,
    element: createElement(CreatePhysicalCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: CREATE_VIRTUAL_CARD_ROUTE,
    element: createElement(CreateVirtualCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: LINK_PHYSICAL_CARD_ROUTE,
    element: createElement(LinkPhysicalCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: CARD_CONTROL_ROUTE(':id'),
    element: createElement(CardControl),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: EDIT_CARD_COLOR_ROUTE(':id'),
    element: createElement(EditColorContainer),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: EDIT_CARD_LIMITS_ROUTE(':id'),
    element: createElement(EditCardLimits),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: DEPOSIT_CARD_ROUTE(':id'),
    element: createElement(DepositCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
  {
    path: WITHDRAW_CARD_ROUTE(':id'),
    element: createElement(WithdrawCard),
    restricted: true,
    showNav: false,
    showSideBar: false,
    isKycLimited: true,
  },
];
