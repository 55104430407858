import { useMutation, useQuery } from '@tanstack/react-query';
import useDisclosure from 'hooks/useDisclosure';
import { useGetAccount } from 'services/account';

import {
  getSelfieCaptureResult,
  getSelfieSessionId,
  getSupportedDocs,
  postAccountVerify,
  postSubmitAccount,
  postUploadDocs,
} from './function';
import { SelfieType, verificationKeys } from './key';

export const useAccountVerify = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postAccountVerify,
  });

  return { mutate, isPending, isError, error };
};

export const useUploadDocsMutation = () => {
  const { mutate, isPending, isError, error, isSuccess } = useMutation({
    mutationFn: postUploadDocs,
  });

  return { mutate, isPending, isError, error, isSuccess };
};

export const useSubmitAccountMutation = () => {
  const {
    close: closeSuccessModal,
    isOpen: successModalIsOpen,
    open: openSuccessModal,
  } = useDisclosure();
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postSubmitAccount,
    onSuccess: () => openSuccessModal(),
  });

  return { mutate, isPending, isError, error, successModalIsOpen, closeSuccessModal };
};

export const useGetSupportedDocs = () => {
  const { data, isPending, isError } = useQuery({
    queryKey: [verificationKeys.GET_SUPPORTED_DOCS_KEY],
    queryFn: getSupportedDocs,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useInitiateSelfie = () => {
  const { data: accountResponse } = useGetAccount();
  const { data, isPending, isError } = useQuery({
    queryKey: [verificationKeys.INITIATE_SELFIE_KEY],
    queryFn: () => getSelfieSessionId(accountResponse?.data.id as string),
    staleTime: 120000000,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetSelfieCaptureResult = (type: SelfieType, sessionId: string, userId: string) => {
  const { data, isPending, isError, error, isSuccess } = useQuery({
    queryKey: [verificationKeys.GET_SELFIE_CAPTURE_RESULT_KEY, sessionId],
    queryFn: () => getSelfieCaptureResult(userId, sessionId ?? '', type),
    staleTime: 120000000,
    retry: 1,
    enabled: !!type && !!sessionId && !!userId,
  });

  return {
    data,
    isPending,
    isError,
    error,
    isSuccess,
  };
};

// export const useGetSelfieCaptureResultMutation = (type: SelfieType, sessionId: string, userId: string) => {
//   const { mutate, isPending, isError, error, data } = useMutation({
//     // queryKey: [verificationKeys.GET_SELFIE_CAPTURE_RESULT_KEY],
//     mutationFn: () => getSelfieCaptureResult(userId, sessionId, type),
//     // staleTime: 120000000,
//   });

//   return {
//     data,
//     isPending,
//     isError,
//     error,
//     mutate,
//   };
// };

// https://selfie.platnova.co/?session_id=9f1215ed-5d32-4b90-a6a0-032b77ee90cc&host=https://dev.platnova.co
