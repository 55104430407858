import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';

import { GET_AIRTIMES_QUERY_KEY, GET_FEATURED_GIFTCARDS_QUERY_KEY, GET_GIFTCARDS_QUERY_KEY } from './constants';
import { getFeaturedGiftcards, getGiftcards, getRefills, postBuyAirtime, postBuyBetting, postBuyCable, postBuyData, postBuyElectricity, postBuyGiftcard, postVerifyBetting, postVerifyCable, postVerifyElectricity } from './function';

export const useGetGiftcards = ({ page, name }: { page: number, name: string }) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_GIFTCARDS_QUERY_KEY, page, name],
    queryFn: () => getGiftcards({ page, name }),
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetRefills = ({ page, country, type }: { page: number; country: string; type: number }) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_AIRTIMES_QUERY_KEY, country, page],
    queryFn: () => getRefills({ page, country, type }),
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useGetFeaturedGiftcards = ({ page }: { page: number }) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [GET_FEATURED_GIFTCARDS_QUERY_KEY, page],
    queryFn: () => getFeaturedGiftcards({ page }),
    placeholderData: keepPreviousData,
  });

  return {
    data,
    isPending,
    isError,
  };
};

export const useBuyGiftcard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyGiftcard,
  });

  return { mutate, isPending, isError, error };
};

export const useBuyAirtime = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyAirtime,
  });

  return { mutate, isPending, isError, error };
};

export const useBuyBetting = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyBetting,
  });

  return { mutate, isPending, isError, error };
};

export const useBuyElectricity = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyElectricity,
  });

  return { mutate, isPending, isError, error };
};

export const useBuyCable = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyCable,
  });

  return { mutate, isPending, isError, error };
};

export const useBuyData = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postBuyData,
  });

  return { mutate, isPending, isError, error };
};

export const useVerifyBetting = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyBetting,
  });

  return { mutate, isPending, isError, error };
};

export const useVerifyElectricity = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyElectricity,
  });

  return { mutate, isPending, isError, error };
};

export const useVerifyCable = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyCable,
  });

  return { mutate, isPending, isError, error };
};