import { ChevronLeft } from 'lucide-react'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetVaults } from 'services/vault'
import { VaultResponse } from 'services/vault/types'
import { formatAmount, removeNonDigit } from 'utils/numberFormat'

import DashboardCardLayout from '../components/dashboardCardLayout'
import SubRouteLayout from '../components/subRouteLayout'

const VaultDetails = () => {
  const navigate = useNavigate();
  const { data: vaultResponse, isPending } = useGetVaults()
  const { id } = useParams()

  const vault = vaultResponse?.data.find(x => x.transaction.id === id)

  const progress = (item: VaultResponse | undefined) => {
    const createdAt = moment(item?.created_at)
    const targetDate = createdAt.clone().add(item?.duration, 'days');
    const now = moment()

    const elapsedTime = now.diff(createdAt);
    const totalDuration = targetDate.diff(createdAt);

    const progress = (elapsedTime / totalDuration) * 100;

    return Math.min(100, Math.max(0, progress));
  }

  const daysLeft = (item: VaultResponse | undefined): number => {
    if (!item) return 0;

    const createdAt = moment(item.created_at);
    const targetDate = createdAt.clone().add(item.duration, 'days');
    const now = moment();
    const daysRemaining = targetDate.diff(now, 'days');

    return Math.max(0, daysRemaining);
  };

  const daysPast = (item: VaultResponse | undefined): number => {
    if (!item) return 0;

    const createdAt = moment(item.created_at);
    const now = moment();
    const daysPassed = now.diff(createdAt, 'days');

    return Math.max(0, daysPassed);
  };

  const interest = Number(removeNonDigit(vault?.amount.toString() as string)) * (Number(vault?.rate) / 100)

  const accrued = (daysPast(vault) * interest) / Number(vault?.duration)

  return (
    vault && !isPending && <SubRouteLayout header='Vault Details'>
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="space-y-5 md:space-y-10 xl:w-[37.5rem]">
            <p className='font-medium text-xl md:text-2xl'>Vault Details</p>

            <DashboardCardLayout>
              <p className='font-medium text-sm md:text-xl'>{vault?.currency.toUpperCase()} {formatAmount(vault?.amount.toString() as string)}</p>
              <div className='mt-5'>
                <p className='text-grey-text text-end text-[0.635rem] md:text-xs'>{daysLeft(vault)} days left</p>
                <div className='bg-[#D9D9D9] h-1 md:h-2 rounded-full w-full my-1'>
                  <div style={{ width: `${progress(vault)}%` }} className="h-1 md:h-2 bg-platnova-purple rounded-full"></div>
                </div>
                <p>Accrued interest: <span>{vault?.currency.toUpperCase()} {formatAmount(accrued.toString())} out of {formatAmount(interest.toString())}</span></p>
              </div>
            </DashboardCardLayout>

            <DashboardCardLayout className='mt-10 space-y-4 md:space-y-6'>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Date Created</p>
                <p className='text-xs md:text-base font-medium'>{moment(vault.created_at).format('Do MMMM YYYY')}</p>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Principal Amount</p>
                <p className='text-xs md:text-base font-medium'>{vault.currency.toUpperCase()} {formatAmount(vault.amount.toString())}</p>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Duration</p>
                <p className='text-xs md:text-base font-medium'>{vault.duration} days</p>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Total Interest</p>
                <p className='text-xs md:text-base font-medium'>{vault.currency.toUpperCase()} {formatAmount(interest.toString())}</p>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Total to earn</p>
                <p className='text-xs md:text-base font-medium'>{vault.currency.toUpperCase()} {formatAmount((interest + vault.amount).toString())}</p>
              </div>
              <div className='flex justify-between items-center'>
                <p className='text-xs md:text-base text-grey-text'>Payback date</p>
                <p className='text-xs md:text-base font-medium'>{moment(vault.created_at).add(vault.duration, 'days').format('Do MMMM YYYY')}</p>
              </div>
            </DashboardCardLayout>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  )
}

export default VaultDetails