import Button from 'components/Buttons/Button';
import Modal, { ModalProps } from 'components/modal';
import { X } from 'lucide-react';

const DeleteCardModal = ({ ...props }: ModalProps) => {
  return (
    <Modal
      {...props}
      className="p-8 w-full max-w-xl md:max-w-md md:w-full scroll-component pb-24 xl:pb-8 space-y-6"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium">Remove Card</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>

      <p>Are you sure you want to remove this card? This operation cannot be undone</p>

      <div className="flex justify-between space-x-6">
        <Button className="w-full" variant="ghost" onClick={props.close}>
          Not now
        </Button>
        <Button className="w-full" variant="danger">
          Yes, remove
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteCardModal;
