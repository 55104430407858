import CardsGroup from 'assets/images/cardsGroup';
import Button from 'components/Buttons/Button';
import Loader from 'components/Loader';
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import useDisclosure from 'hooks/useDisclosure';
import { EyeIcon, EyeOff, Plus, SnowflakeIcon } from 'lucide-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { IoIosShareAlt } from 'react-icons/io';
import { IoSettingsSharp } from 'react-icons/io5';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { CARD_CONTROL_ROUTE, DEPOSIT_CARD_ROUTE, WITHDRAW_CARD_ROUTE } from 'routes/path';
import { useGetCards, useRevealCard } from 'services/cards';
import { useAppDispatch, useAppSelector } from 'store';
import { setCardIndex } from 'store/slices/cards/cardsSlice';
import { CARD_INDEX, CARD_SLICE_REDUCER_PATH } from 'store/slices/cards/constants';
import { Card } from 'types/card';
import { formatAmount } from 'utils/numberFormat';

import CreateCardModal from './components/createCardModal';
import DeleteCardModal from './components/deleteCardModal';
import EmptyCard from './components/emptyCard';
import FreezeCardModal from './components/freezeCardModal';
import RecentActivityStatus from '../accounts/components/recentActivityStatus';
import DashboardCardLayout from '../components/dashboardCardLayout';
import PaddedContainer from '../components/paddedContainer';

const Cards = () => {
  const [revealedCards, setRevealedCards] = useState<{ [key: number]: boolean }>({});
  const navigate = useNavigate();
  const toggleCardReveal = (index: number) => {
    setRevealedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // const { mutate: freezeCard, isPending: isLoadingFreezeCard,} = useFreezeCard()
  // const { mutate: unfreezeCard, isPending: isLoadingUnfreezeCard,} = useUnfreezeCard()

  const [api, setApi] = useState<CarouselApi | null>(null);
  const dispatch = useAppDispatch();
  const { [CARD_INDEX]: cardIndex } = useAppSelector((state) => state[CARD_SLICE_REDUCER_PATH]);

  const isRevealed = revealedCards[cardIndex] || false;

  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();
  const {
    close: closeCreateCardModal,
    isOpen: createCardModalIsOpen,
    open: openCreateCardModal,
  } = useDisclosure();

  const cards = getCardsResponse?.data;

  useEffect(() => {
    if (cards) {
      const initialRevealedState = cards.reduce(
        (acc: { [key: number]: boolean }, _card: Card, index: number) => {
          acc[index] = false; // Set default reveal status to 'false'
          return acc;
        },
        {}
      );

      setRevealedCards(initialRevealedState);
    }
  }, [cards]);

  const formatColor = (color: string) => {
    const format = color.replace(/0xff/g, '#');
    return format;
  };

  const {
    mutate: revealCard,
    isPending: isLoadingRevealCard,
    data: revealedCardResponse,
  } = useRevealCard();

  useEffect(() => {
    if (!api) {
      return;
    }

    const logCurrentIndex = () => {
      const selectedIndex = api.selectedScrollSnap();
      dispatch(setCardIndex(selectedIndex));
    };

    logCurrentIndex();
    api.on('select', logCurrentIndex);
    api.on('reInit', logCurrentIndex);

    return () => {
      api.off('select', logCurrentIndex);
      api.off('reInit', logCurrentIndex);
    };
  }, [api, dispatch]);

  // console.log(revealedCards)

  const {
    close: closeFreezeCardModal,
    isOpen: freezeCardModalIsOpen,
    open: openFreezeCardModal,
  } = useDisclosure();
  const {
    close: closeDeleteCardModal,
    isOpen: deleteCardModalIsOpen,
    open: openDeleteCardModal,
  } = useDisclosure();

  return (
    <PaddedContainer>
      {!isLoadingGetCardsResponse && !getCardsResponse?.data.length ? (
        <DashboardCardLayout className="my-auto w-full flex flex-col items-center text-center space-y-5">
          <CardsGroup />
          <p className="text-xl md:text-2xl font-medium">Create your Platnova Card</p>
          <p className="text-black/60 text-sm md:text-base">
            Tap here to instantly create a new physical or virtual card. Ideal for secure,
            hassle-free shopping both online and in-store.
          </p>
          <Button
            className="w-full md:w-1/2 text-base"
            variant="secondary"
            onClick={openCreateCardModal}
          >
            Create Card
          </Button>
        </DashboardCardLayout>
      ) : !isLoadingGetCardsResponse && getCardsResponse?.data.length ? (
        <div className="space-y-10 w-full flex flex-col items-center">
          <div className="w-full max-w-[37.5rem] flex justify-end">
            <Button
              variant="secondary"
              className="flex space-x-2 items-center shadow-none md:h-auto h-auto"
              onClick={openCreateCardModal}
            >
              <Plus className="w-4 aspect-square" />
              <span className="text-base font-medium">Create Card</span>
            </Button>
          </div>
          <Carousel className="md:w-full max-w-[80%] xl:max-w-[37.5rem]" setApi={setApi}>
            <CarouselContent>
              {cards &&
                cards?.map((card, index) => (
                  <CarouselItem className="basis[90%] md:basis-[70%]" key={index}>
                    <EmptyCard
                      card={card}
                      isRevealed={revealedCards[index]}
                      revealedCard={revealedCardResponse?.data}
                      isLoadingReveal={isLoadingRevealCard}
                      type={card.info.type === 'virtual' ? 'virtual' : 'physical'}
                      background={`linear-gradient(to right, ${formatColor(
                        card.info.colors[0]
                      )}, ${formatColor(card.info.colors[1])}, ${formatColor(card.info.colors[2])}`}
                    />
                  </CarouselItem>
                ))}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>

          <div className="w-full flex space-x-6 md:space-x-12 justify-center">
            <div className="flex flex-col items-center space-y-2">
              <button
                onClick={() => {
                  toggleCardReveal(cardIndex);
                  !isRevealed && revealCard(getCardsResponse.data[cardIndex].id);
                }}
                className="bg-white rounded-[10px] shadow-sm w-12 aspect-square flex justify-center items-center"
              >
                {!isRevealed ? (
                  <EyeIcon className="text-black/70 w-4 aspect-video" />
                ) : (
                  <EyeOff className="text-black/70 w-4 aspect-video" />
                )}
              </button>
              <p className="text-xs">{!isRevealed ? 'Show details' : 'Hide details'}</p>
            </div>

            <div className="flex flex-col items-center space-y-2">
              <button
                className="bg-white rounded-[10px] shadow-sm w-12 aspect-square flex justify-center items-center"
                onClick={openFreezeCardModal}
              >
                <SnowflakeIcon className="text-black/70 w-4 aspect-video" />
              </button>
              <p className="text-xs">
                {getCardsResponse.data[cardIndex].status === 'active'
                  ? 'Freeze card'
                  : 'Unfreeze card'}
              </p>
            </div>

            <div className="flex flex-col items-center space-y-2">
              <button
                className="bg-white rounded-[10px] shadow-sm w-12 aspect-square flex justify-center items-center"
                onClick={openDeleteCardModal}
              >
                <FaTrash className="stroke-white fill-error-primary w-4 aspect-video" />
              </button>
              <p className="text-xs text-error-primary">Delete card</p>
            </div>

            <div className="flex flex-col items-center space-y-2">
              <button
                className="bg-white rounded-[10px] shadow-sm w-12 aspect-square flex justify-center items-center"
                onClick={() => navigate(CARD_CONTROL_ROUTE(getCardsResponse.data[cardIndex].id))}
              >
                <IoSettingsSharp className="fill-black/70 stroke-white w-4 aspect-video" />
              </button>
              <p className="text-xs">Settings</p>
            </div>
          </div>

            <DashboardCardLayout className="flex flex-col items-center w-full max-w-[37.5rem]">
            <p className="text-xs md:text-base">Available balance</p>
            {isLoadingGetCardsResponse && <Skeleton height={32} width={200} className="mt-2" />}
            {cards && !isLoadingGetCardsResponse && (
              <div>
                <p className="text-2xl md:text-[2rem] font-medium">
                  {cards[cardIndex].currency.toUpperCase()} {formatAmount(cards[cardIndex].amount)}
                </p>
                <FreezeCardModal
                  type={cards[cardIndex].status === 'active' ? 'freeze' : 'unfreeze'}
                  cardId={cards[cardIndex].id}
                  close={closeFreezeCardModal}
                  isOpen={freezeCardModalIsOpen}
                />
                <DeleteCardModal close={closeDeleteCardModal} isOpen={deleteCardModalIsOpen} />
              </div>
            )}

            {cards && !isLoadingGetCardsResponse && (
              <div className="flex justify-center items-end space-x-16 md:space-x-28 mt-5 md:mt-10">
                <div className="flex flex-col items-center">
                  <button
                    onClick={() => navigate(DEPOSIT_CARD_ROUTE(cards[cardIndex].id))}
                    className="bg-green-500/10 p-2 rounded-full"
                  >
                    <IoIosShareAlt className="text-4xl text-green-500" />
                  </button>
                  <span>Deposit</span>
                </div>
                <div className="flex flex-col items-center">
                  <button
                    onClick={() => navigate(WITHDRAW_CARD_ROUTE(cards[cardIndex].id))}
                    className="bg-orange-500/10 p-2 rounded-full"
                  >
                    <IoIosShareAlt className="text-4xl text-orange-500 scale-x-[-1]" />
                  </button>
                  <span>Withdraw</span>
                </div>
              </div>
            )}
          </DashboardCardLayout>

            <div className="space-y-3 w-full max-w-[37.5rem]">
            <div className="flex justify-between items-end">
              <p className="text-xl font-medium">Transactions</p>
              <button className="text-platnova-purple text-sm font-medium">See all</button>
            </div>
            <DashboardCardLayout className="w-full space-y-8">
              {cards &&
                cards[cardIndex].events.slice(0, 3).map((event) => (
                  <div key={event.event_id} className="flex justify-between">
                    <div className="flex items-center space-x-5">
                      <div className="bg-platnova-secondary w-10 aspect-square rounded-full" />
                      <div className="space-y-2">
                        <p className="text-sm">{event.description}</p>
                        <p className="text-xs">
                          {moment(event.created_at).startOf('second').fromNow()}
                        </p>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <p className="text-sm font-medium">
                        {event.currency.toUpperCase()} {formatAmount(event.amount)}
                      </p>
                      <RecentActivityStatus status={event.status} />
                    </div>
                  </div>
                ))}
              {isLoadingGetCardsResponse && <Skeleton height={78} count={3} />}
            </DashboardCardLayout>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <CreateCardModal close={closeCreateCardModal} isOpen={createCardModalIsOpen} />
    </PaddedContainer>
  );
};

export default Cards;
