import Button from 'components/Buttons/Button';
import useErrorNotification from 'components/error/useErrorNotification';
import Input from 'components/Inputs/input';
import { REGISTER_EMAIL_KEY, REGISTER_REFERRAL_CODE_KEY } from 'lib/constants/auth/register';
import { useSearchParams } from 'react-router-dom';

import useRegisterForm from '../hooks/useRegisterForm';

const RegisterForm = () => {
  const { getInputProps, onSubmit, isValid, errors, isError, isPending, registerError } =
    useRegisterForm();

  useErrorNotification(isError, registerError);

  const [searchParams] = useSearchParams();
  const ref = searchParams.get('code')

  return (
    <form className="mt-10 flex flex-col items-center" onSubmit={onSubmit}>
      <Input
        id={REGISTER_EMAIL_KEY}
        label="Email address"
        inputMode="email"
        type="email"
        className="xl:w-[29.25rem]"
        placeholder="Enter email address"
        error={errors[REGISTER_EMAIL_KEY]}
        {...getInputProps(REGISTER_EMAIL_KEY)}
      />

      <Input
        id={REGISTER_REFERRAL_CODE_KEY}
        label="Referral code"
        className="xl:w-[29.25rem]"
        placeholder="Enter referral code"
        disabled={ref && ref.length > 0 ? true : false}
        error={errors[REGISTER_REFERRAL_CODE_KEY]}
        {...getInputProps(REGISTER_REFERRAL_CODE_KEY)}
      />

      <p className="text-xs xl:text-sm mt-3 mb-5 font-normal">
        By clicking this button, you agree to the{' '}
        <a
          href="https://platnova.co/terms"
          target="_blank"
          rel="noreferrer"
          className="text-platnova-purple"
        >
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a
          href="https://platnova.co/privacy-policy"
          target="_blank"
          rel="noreferrer"
          className="text-platnova-purple"
        >
          Privacy Policy
        </a>
      </p>

      <Button
        variant="purple"
        className="w-full"
        isDisabled={!isValid || isPending}
        isLoading={isPending}
        type="submit"
      >
        Register
      </Button>
    </form>
  );
};

export default RegisterForm;
