import { cn } from 'lib/utils';
import { ReactNode } from 'react';

const DashboardCardLayout = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
    // initial={{ scale: 0.8 }}
    // animate={{ scale: 1 }}
    // transition={{
    //   type: 'spring',
    //   stiffness: 150,
    //   damping: 20,
    // }}
      className={cn(
        'bg-white w-full xl:w-[37.5rem] p-4 xl:p-8 rounded-[10px] shadow-gray-200/50 shadow-md',
        className
      )}
    >
      {children}
    </div>
  );
};

export default DashboardCardLayout;
