import Chip from 'assets/icons/chip';
import { Copy } from 'lucide-react';
import { useAppSelector } from 'store';
import { ACTIVE_CARD_BRAND, CARD_SLICE_REDUCER_PATH } from 'store/slices/cards/constants';
import { Card, RevealCardResponse } from 'types/card';
import { copyToClipboard } from 'utils/clipboard';

const EmptyCard = ({
  background,
  type,
  card,
  revealedCard,
  isRevealed = false,
}: // isLoadingReveal,
{
  background: string;
  type: 'physical' | 'virtual';
  card?: Card;
  revealedCard?: RevealCardResponse;
  isRevealed?: boolean;
  isLoadingReveal?: boolean;
}) => {
  const { [ACTIVE_CARD_BRAND]: selectedCard } = useAppSelector(
    (state) => state[CARD_SLICE_REDUCER_PATH]
  );

  return (
    <div
      style={{ background: background }}
      className={`p-5 rounded-2xl text-white w-72 md:w-96 h-48 md:h-60 transition-transform duration-700 ${
        isRevealed && 'cardRevealed'
      }`}
    >
      {!isRevealed && (
        <div>
          <div className="justify-between items-center flex w-full mb-6">
            <p className="text-xs md:text-base">
              {card && card.status === 'active' ? card.info.label : card?.title}
            </p>
            <p className="text-xs md:text-base italic">
              {type === 'physical' && 'Physical'} {type === 'virtual' && 'Virtual'} card
            </p>
          </div>
          <Chip />
          <div className="flex justify-between items-center w-full mt-10 md:mt-16">
            <p className="text-sm md:text-lg">
              {card ? card.info.masked_pan : 'Cardholder Number'}
            </p>
            {!card && selectedCard && (
              <img
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${selectedCard.id}.svg`}
                alt="gg"
                className="w-10 h-max"
              />
            )}
            {card && (
              <img
                src={`https://res.cloudinary.com/platnova/assets/payment_methods/${card.info.brand}.svg`}
                alt="gg"
                className="w-10 h-max"
              />
            )}
          </div>
        </div>
      )}
      {isRevealed && card && revealedCard && (
        <div className="backCard">
          <p className="text-xs md:text-sm">Card number</p>
          <div className="flex items-center space-x-3 mt-2">
            <p className="text-xs xl:text-base font-medium">{revealedCard.number}</p>
            <button onClick={() => copyToClipboard('Card number', revealedCard.number)}>
              <Copy size={15} className="" />
            </button>
          </div>

          <div className="flex justify-between mt-10">
            <div className="flex space-x-4 items-start">
              <div>
                <p className="text-xs md:text-sm">Expiry date</p>
                <div className="flex items-center space-x-3 mt-2">
                  <p className="text-xs xl:text-base font-medium">
                    {card.info.expiry_month}/{card.info.expiry_year}
                  </p>
                </div>
              </div>

              <div>
                <p className="text-xs md:text-sm">CVV</p>
                <div className="flex items-center space-x-3 mt-2">
                  <p className="text-xs xl:text-base font-medium">{revealedCard.cvv}</p>
                  <button onClick={() => copyToClipboard('CVV', revealedCard.cvv)}>
                    <Copy size={15} className="" />
                  </button>
                </div>
              </div>
            </div>
            <img
              src={`https://res.cloudinary.com/platnova/assets/payment_methods/${card.info.brand}.svg`}
              alt="gg"
              className="w-10 h-max"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EmptyCard;
