import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardBrands } from 'types/configuration';

import {
  ACTIVE_CARD_BRAND,
  ACTIVE_VIRTUAL_CARD_BRAND,
  CARD_INDEX,
  CARD_SLICE_REDUCER_PATH,
  cardSliceInitialState,
  CardSliceType,
  ColorType,
  LinkCardInputValuesType,
  PHYSICAL_CARD_COLOR_KEY,
  PHYSICAL_CARD_NUMBER,
  VIRTUAL_CARD_COLOR_KEY,
} from './constants';

const cardSlice = createSlice({
  name: CARD_SLICE_REDUCER_PATH,
  initialState: cardSliceInitialState,
  reducers: {
    resetCardState: () => {
      return cardSliceInitialState;
    },
    setActiveCardBrand: (state, { payload }: PayloadAction<null | CardBrands>) => {
      state[ACTIVE_CARD_BRAND] = payload;
    },
    setActiveVirtualCardBrand: (state, { payload }: PayloadAction<null | CardBrands>) => {
      state[ACTIVE_VIRTUAL_CARD_BRAND] = payload;
    },
    setPhysicalCardColor: (state, { payload }: PayloadAction<ColorType>) => {
      state[PHYSICAL_CARD_COLOR_KEY] = payload;
    },
    setVirtualCardColor: (state, { payload }: PayloadAction<ColorType>) => {
      state[VIRTUAL_CARD_COLOR_KEY] = payload;
    },
    setNumberOfPhysicalCards: (state, { payload }: PayloadAction<number>) => {
      state[PHYSICAL_CARD_NUMBER] = payload;
    },
    setCardIndex: (state, { payload }: PayloadAction<number>) => {
      state[CARD_INDEX] = payload;
    },
    setLinkCardInputValues: (state, { payload }: PayloadAction<LinkCardInputValuesType>) => {
      state.linkCardInputValues = payload;
    },
    setCreatePhysicalCardStage: (state, action: PayloadAction<Pick<CardSliceType, 'stage'>>) => ({
      ...state,
      ...action.payload,
    }),
    setLinkCardStage: (state, action: PayloadAction<Pick<CardSliceType, 'linkCardStage'>>) => ({
      ...state,
      ...action.payload,
    }),
    setCreateVirtualCardStage: (
      state,
      action: PayloadAction<Pick<CardSliceType, 'virtualStage'>>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const cardBrandReducer = cardSlice.reducer;

export const {
  setActiveCardBrand,
  setPhysicalCardColor,
  setNumberOfPhysicalCards,
  setCreatePhysicalCardStage,
  resetCardState,
  setActiveVirtualCardBrand,
  setCreateVirtualCardStage,
  setVirtualCardColor,
  setLinkCardStage,
  setCardIndex,
  setLinkCardInputValues,
} = cardSlice.actions;
