export const REGISTER_EMAIL_KEY = 'email' as const;
export const REGISTER_REFERRAL_CODE_KEY = 'referral_code' as const;
export const REGISTER_OTP_KEY = 'token' as const;
export const REGISTER_PASSWORD_KEY = 'password' as const;
export const REGISTER_PASSWORD_CONFIRM_KEY = 'password_confirm' as const;
export const REGISTER_FIRST_NAME_KEY = 'first_name' as const;
export const REGISTER_LAST_NAME_KEY = 'last_name' as const;
export const REGISTER_COUNTRY_OF_RESIDENCE_KEY = 'country' as const;
export const REGISTER_PHONE_NUMBER_KEY = 'phone' as const;
export const REGISTER_DIAL_CODE_KEY = 'dial_code' as const;
export const REGISTER_COUNTRY_CODE_KEY = 'country_code' as const;
export const ACCOUNT_VERIFIED_ERROR_MESSAGE_KEY =
  'account is verified, please proceed to login' as const;
