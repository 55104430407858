import Button from 'components/Buttons/Button';
import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import Modal, { ModalProps } from 'components/modal';
import { X } from 'lucide-react';
import { useFreezeCard, useUnfreezeCard } from 'services/cards';

const FreezeCardModal = ({
  ...props
}: ModalProps & { cardId: string; type: 'freeze' | 'unfreeze' }) => {
  const { mutate: freezeCard, isPending: isLoadingFreezeCard } = useFreezeCard();

  const { mutate: unfreezeCard, isPending: isLoadingUnfreezeCard } = useUnfreezeCard();

  const handleSubmit = async () => {
    try {
      if (props.type === 'freeze') {
        freezeCard(props.cardId, {
          onSuccess: () => {
            notifySuccess(`Card successfully frozen`);
            props.close();
          },
          onError: (err) => {
            handleErrors(err);
          },
        });
      } else {
        unfreezeCard(props.cardId, {
          onSuccess: () => {
            notifySuccess(`Card successfully unfrozen`);
            props.close();
          },
          onError: (err) => {
            handleErrors(err);
          },
        });
      }
    } catch (err) {
      handleErrors(err);
    }
  };

  return (
    <Modal
      {...props}
      className="p-8 w-full max-w-xl md:max-w-md md:w-full scroll-component pb-24 xl:pb-8 space-y-6"
    >
      <div className="flex justify-between pb-3 border-b">
        <p className="text-xl md:text-2xl font-medium capitalize">{props.type} Card</p>
        <X size={25} className="cursor-pointer" onClick={props.close} />
      </div>

      <p>Are you sure you want to {props.type} this card?</p>

      <div className="flex justify-between space-x-6">
        <Button className="w-full" variant="ghost" onClick={props.close}>
          Not now
        </Button>
        <Button
          isLoading={isLoadingFreezeCard || isLoadingUnfreezeCard}
          onClick={handleSubmit}
          className="w-full capitalize"
          variant="danger"
        >
          Yes, {props.type}
        </Button>
      </div>
    </Modal>
  );
};

export default FreezeCardModal;
