import Limit from 'assets/icons/limit.png';
import Account from 'assets/settings/account.png';
import Beneficiary from 'assets/settings/beneficiary.png';
import Preferences from 'assets/settings/preferences.png';
import Refer from 'assets/settings/refer.png';
import Security from 'assets/settings/security.png';
import Support from 'assets/settings/support.png';
import Avatar from 'components/avatar';
import { BadgeCheck, UserRoundPen } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ACCOUNT_DETAILS_ROUTE,
  ACCOUNTS_SETTINGS_ROUTE,
  LOGOUT_ROUTE,
  REFER_ROUTE,
  SETTINGS_BENEFICIARIES_ROUTE,
  SETTINGS_SECURITY_ROUTE,
} from 'routes/path';
import { useGetAccount } from 'services/account';

import SettingsButton from './components/settingsButton';
import PaddedContainer from '../components/paddedContainer';

const Settings = () => {
  const { data } = useGetAccount();
  const user = data?.data;
  const navigate = useNavigate();

  return (
    <PaddedContainer>
      <div className="w-full xl:max-w-[37.5rem]">
        {user && (
          <div className="flex justify-between">
            <button
              onClick={() => navigate(ACCOUNT_DETAILS_ROUTE)}
              className="flex space-x-3 items-center"
            >
              <Avatar
                avatar={user.preference.avatar}
                className="w-10 h-10 xl:w-14 xl:h-14 my-auto"
              />
              <div>
                <div className="flex items-center space-x-2">
                  <p className="text-sm xl:text-xl font-medium text-start">
                    {user.first_name?.toUpperCase()} {user.last_name?.toUpperCase()}
                  </p>
                  {user.status === 'ACTIVE' && <BadgeCheck fill="#1CB222" stroke="white" />}
                </div>
                <p className="text-platnova-purple text-start text-xs xl:text-xl">
                  @{user.username}
                </p>
              </div>
            </button>
            <button type="button" className="bg-white h-max p-3 rounded-full">
              <UserRoundPen stroke="#6D6A6A" />
            </button>
          </div>
        )}

        <div className="xl:bg-white xl:px-5 mt-14 rounded-[10px] divide-y-2">
          <SettingsButton
            image={Account}
            link={ACCOUNTS_SETTINGS_ROUTE}
            title="Accounts"
            subtitle="View your account details"
          />
          <SettingsButton
            image={Limit}
            title="Transaction Limits"
            comingSoon
            subtitle="View your transaction limits"
          />
          <SettingsButton
            image={Beneficiary}
            link={SETTINGS_BENEFICIARIES_ROUTE}
            title="Beneficiary"
            subtitle="Manage your saved beneficiaries"
          />
          <SettingsButton
            image={Preferences}
            comingSoon
            title="Preferences"
            subtitle="Customize your app"
          />
          <SettingsButton
            image={Support}
            title="Help & Support"
            comingSoon
            subtitle="Contact our dedicated support team"
          />
          <SettingsButton
            image={Security}
            title="Security"
            link={SETTINGS_SECURITY_ROUTE}
            subtitle="Manage password, PIN & two-factor authentication"
          />
          <SettingsButton
            image={Refer}
            title="Refer & Earn"
            link={REFER_ROUTE}
            subtitle="Invite your friends and earn big"
          />
        </div>

        <div className="mt-20 flex flex-col items-center">
          <Link to={LOGOUT_ROUTE} className="text-base xl:text-lg font-medium text-error-primary">
            Sign out
          </Link>
          <p className="mt-5 text-sm xl:text-base font-medium text-grey-text">Version 0.1.0</p>
        </div>
      </div>
    </PaddedContainer>
  );
};

export default Settings;
