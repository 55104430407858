import { useMutation } from '@tanstack/react-query';
import { USER_TOKEN_COOKIE_KEY } from 'services/constants';
import cookie from 'services/cookie';
import { GeneralApiResponse } from 'types';
import { AuthUser } from 'types/auth';

import { postLogin, postVerifyLogin } from './function';

export const useLogin = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postLogin,
  });

  return { mutate, isPending, isError, error };
};

export const useVerifyLogin = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postVerifyLogin,
    onSuccess: (res: GeneralApiResponse<AuthUser>) => {
      cookie.set(USER_TOKEN_COOKIE_KEY, res.data.token);
    },
  });

  return { mutate, isPending, isError, error };
};
