import Button from 'components/Buttons/Button';
import Input from 'components/Inputs/input';
import { Carousel } from 'components/ui/carousel';
import { useFormik } from 'formik';
import {
  LINK_CARD_COLORS_KEY,
  LINK_CARD_LABEL_KEY,
  LINK_CARD_PAN_KEY,
} from 'services/cards/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { setLinkCardInputValues, setLinkCardStage } from 'store/slices/cards/cardsSlice';
import { CARD_SLICE_REDUCER_PATH, PHYSICAL_CARD_COLOR_KEY } from 'store/slices/cards/constants';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import { object, string } from 'yup';

import ChooseCardColor from './chooseCardColor';

const LinkCardInputStage = () => {
  const dispatch = useAppDispatch();
  const { linkCardInputValues, [PHYSICAL_CARD_COLOR_KEY]: physicalCardColor } = useAppSelector(
    (state) => state[CARD_SLICE_REDUCER_PATH]
  );

  const formik = useFormik({
    initialValues: {
      [LINK_CARD_LABEL_KEY]: linkCardInputValues[LINK_CARD_LABEL_KEY],
      [LINK_CARD_PAN_KEY]: linkCardInputValues[LINK_CARD_PAN_KEY],
    },
    validationSchema: object().shape({
      [LINK_CARD_LABEL_KEY]: string(),
      [LINK_CARD_PAN_KEY]: string()
        .required('Please enter your card number')
        .min(14, 'Invalid card number'),
    }),
    onSubmit: (values) => {
      const formattedValues = { ...values, [LINK_CARD_COLORS_KEY]: physicalCardColor };
      dispatch(setLinkCardInputValues(formattedValues));
      dispatch(setLinkCardStage({ linkCardStage: 'control' }));
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      ...formik.errors,
    };
  }

  return (
    <div>
      <div className="space-y-10">
        <div>
          <p className="text-lg md:text-2xl font-medium">Enter Platnova card number</p>
          <p className="text-xs md:text-base text-black/70">
            Enter the card number on the front of your Platnova card
          </p>
        </div>
        <form className="space-y-10" onSubmit={formik.handleSubmit}>
          <DashboardCardLayout className="space-y-6">
            <Input
              id={LINK_CARD_PAN_KEY}
              inputMode="numeric"
              label="Card Number"
              {...getInputProps(LINK_CARD_PAN_KEY)}
              placeholder="Enter your card number"
            />
            <Input
              id={LINK_CARD_LABEL_KEY}
              label="Card Label"
              placeholder="General"
              {...getInputProps(LINK_CARD_LABEL_KEY)}
            />
          </DashboardCardLayout>

          <Carousel>
            <ChooseCardColor type="physical" />
          </Carousel>

          <Button variant="purple" type="submit" className="w-full">
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LinkCardInputStage;
