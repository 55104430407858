import Flag from 'components/flag'
import { ChevronLeft } from 'lucide-react'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetConfigurations } from 'services/configurations'
import { useGetVaults, useGetVaultWallets } from 'services/vault'
import { VaultResponse } from 'services/vault/types'
import { Configuration, VaultPlansValue } from 'types/configuration'
import { formatAmount, removeNonDigit } from 'utils/numberFormat'

import DashboardCardLayout from '../components/dashboardCardLayout'
import SubRouteLayout from '../components/subRouteLayout'

const VaultPortfolio = () => {
  const navigate = useNavigate()

  const { data: configurations } = useGetConfigurations()
  const { data: vaultWalletsResponse } = useGetVaultWallets()

  const { data: vaultResponse } = useGetVaults()

  const vaultPlans = configurations?.data.find((item): item is Configuration<VaultPlansValue> => item.name === 'vault_plans')?.value

  const [vault, setVault] = useState<VaultPlansValue | null>(null)

  useEffect(() => {
    vaultPlans && vaultPlans.length > 0 && setVault(vaultPlans[0])
  }, [vaultPlans])

  const vaults = vaultResponse?.data.filter((item) => item.currency === vault?.name)

  const vaultWallet = useMemo(() => {
    return vaultWalletsResponse?.data.find(x => x.currency === vault?.name)
  }, [vault?.name, vaultWalletsResponse?.data])

  const daysLeft = (item: VaultResponse | undefined): number => {
    if (!item) return 0;

    const createdAt = moment(item.created_at);
    const targetDate = createdAt.clone().add(item.duration, 'days');
    const now = moment();
    const daysRemaining = targetDate.diff(now, 'days');

    return Math.max(0, daysRemaining);
  };
  const interest = (item: VaultResponse) => {
    return Number(removeNonDigit(item?.amount.toString() as string)) * (Number(item?.rate) / 100)

  }

  const daysPast = (item: VaultResponse | undefined): number => {
    if (!item) return 0;

    const createdAt = moment(item.created_at);
    const now = moment();
    const daysPassed = now.diff(createdAt, 'days');

    return Math.max(0, daysPassed);
  };

  const progress = (item: VaultResponse | undefined) => {
    const createdAt = moment(item?.created_at)
    const targetDate = createdAt.clone().add(item?.duration, 'days');
    const now = moment()

    const elapsedTime = now.diff(createdAt);
    const totalDuration = targetDate.diff(createdAt);

    const progress = (elapsedTime / totalDuration) * 100;

    return Math.min(100, Math.max(0, progress));
  }

  const accrued = (item: VaultResponse) => {
    return (daysPast(item) * interest(item)) / Number(item?.duration)
  }
  return (
    <SubRouteLayout header='Vault Portfolio'>
      <div className="pb-20 xl:-ml-[4.688rem]">
        <div className="xl:flex items-start xl:space-x-11">
          <button
            onClick={() => navigate(-1)}
            className="flex space-x-1.5 xl:space-x-3 items-center"
          >
            <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
            <span className="text-xs xl:text-base">Back</span>
          </button>

          <div className="space-y-5 md:space-y-10 xl:w-[37.5rem]">
            <p className='font-medium text-xl md:text-2xl'>Portfolio</p>

            <div className='w-full xl:w-[37.5rem] bg-side-bar-gradient border rounded-[10px] mt-5 md:mt-10'>
              <div className='backdrop-blur-[30px] p-5'>
                <div className='flex justify-between items-start'>
                  <div>
                    <p className='text-grey-text text-[0.625rem] md:text-sm'>Total Portfolio</p>
                    <div className='mt-3 text-xl md:text-3xl flex space-x-4 items-center'>
                      <p className='font-medium'>{vault?.name.toUpperCase()} {formatAmount(String(vaultWallet?.amount)) || 0.00}</p>
                      {/* {!visible && <p className='font-medium'>************</p>}
                    <button onClick={() => setVisible(!visible)}>
                      {visible &&
                        <Eye className='w-3 md:w-5' />
                      }
                      {!visible &&
                        <EyeOff className='w-3 md:w-5' />} */}
                      {/* </button> */}
                    </div>
                  </div>

                  <div>
                    {vault &&
                      // <button type="button" onClick={open} className="flex items-center space-x-2 py-5">
                      <div className="flex items-center space-x-2 py-5">
                        <Flag
                          code={vault?.code}
                          className="w-6 h-6"
                        />
                        <p className="text-sm md:text-base">
                          {vault.name.toUpperCase()}
                        </p>
                      </div>
                      // <ChevronDown className="w-6 h-6" />
                      // </button>
                    }
                  </div>
                </div>

                <div className='mt-8 md:mt-10 flex justify-between items-center'>
                  {/* <Button className='flex space-x-2.5 items-center md:h-auto' variant='purple' onClick={openCreateVaultModal}>
                  <Plus className='w-2 md:w-5' />
                  <span>Add Vault</span>
                </Button>
                <Link to={VAULT_PORTFOLIO_ROUTE} className='text-sm md:text-base font-medium text-platnova-purple'>View Portfolio</Link> */}
                </div>
              </div>
            </div>

            <div className='flex space-x-6'>
              {vaultPlans && vault && vaultPlans.map((plan, idx) => (
                <button key={plan.name} type='button' onClick={() => setVault(vaultPlans[idx])} className={plan.name === vault?.name ? 'border-b-2 border-platnova-purple w-16 pb-2 ' : 'w-16 pb-2'}>{plan.name.toUpperCase()}</button>
              ))}
            </div>

            <div className='mt-6 space-y-6'>
              {vaults ? vaults?.map((item, idx) => (
                <DashboardCardLayout key={idx}>
                  <p className='font-medium text-sm md:text-xl'>{item?.currency.toUpperCase()} {formatAmount(item?.amount.toString() as string)}</p>
                  <div className='mt-5'>
                    <p className='text-grey-text text-end text-[0.635rem] md:text-xs'>{daysLeft(item)} days left</p>
                    <div className='bg-[#D9D9D9] h-1 md:h-2 rounded-full w-full my-1'>
                      <div style={{ width: `${progress(item)}%` }} className="h-1 md:h-2 bg-platnova-purple rounded-full"></div>
                    </div>
                    <p>Accrued interest: <span>{item?.currency.toUpperCase()} {formatAmount(accrued(item).toString())} out of {formatAmount(interest(item).toString())}</span></p>
                  </div>
                </DashboardCardLayout>
              )) : <p>No vaults created</p>}
            </div>
          </div>
        </div>
      </div>
    </SubRouteLayout>
  )
}

export default VaultPortfolio