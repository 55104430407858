import Select from 'components/Inputs/select';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from 'components/ui/carousel';
import useDisclosure from 'hooks/useDisclosure';
import { allCountries } from 'lib/countries/countries';
import { cn } from 'lib/utils';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { GIFTCARDS_ROUTE } from 'routes/path';
import { useGetFeaturedGiftcards } from 'services/refill';
import { Airtime } from 'services/refill/constants';
import { GiftCard } from 'services/refill/types';
import { useAppDispatch, useAppSelector } from 'store';
import { setComingSoonModalOpen } from 'store/slices/layout/layoutSlice';
import { BILLS_COUNTRY_KEY, REFILL_SLICE_REDUCER_PATH } from 'store/slices/refill/constants';
import { setActiveRefill, setBillsCountry } from 'store/slices/refill/refillSlice';
import 'swiper/css';
import 'swiper/css/pagination';

import Bills from './bills';
import PurchaseModal from './components/purchaseModal';
import DashboardCardLayout from '../components/dashboardCardLayout';
import PaddedContainer from '../components/paddedContainer';

const Refill = () => {
  const [page, setPage] = useState<'airtime' | 'data' | 'betting' | 'cable' | 'electricity'>('airtime')

  const { isOpen, close, open } = useDisclosure()
  const dispatch = useAppDispatch()

  const customOpen = (active: GiftCard | Airtime) => {
    dispatch(setActiveRefill(active))
    open()
  }

  const { [BILLS_COUNTRY_KEY]: country } = useAppSelector(state => state[REFILL_SLICE_REDUCER_PATH])
  const {
    data: giftcardsResponse,
    // isPending: isLoadingGiftcards
  } = useGetFeaturedGiftcards({ page: 0 });
  // console.log(giftcardsResponse?.data)

  const formatCountries = allCountries.filter((country) => country.name.common !== 'Europe').map((country) => ({
    value: country.cca2,
    img: country.flags.svg,
    label: country.name.common,
  }));

  const handleChange = async (value: string | string[]) => {
    dispatch(setBillsCountry(value as string))

  };


  return (
    <PaddedContainer>
      <div className="w-full md:max-w-[37.5rem]">
        <div className="w-full flex justify-between md:max-w-[37.5rem] items-center">
          <p className="text-sm md:text-base text-grey-text">Giftcards</p>
          <Link to={GIFTCARDS_ROUTE} className="text-platnova-purple text-xs md:text-sm">
            See more
          </Link>
        </div>
        <Carousel className="md:w-full max-w-[80%] mx-auto xl:max-w-[37.5rem] mt-3">
          <CarouselContent>
            {giftcardsResponse?.data.items.map((item, idx) => (
              <CarouselItem
                key={idx}
                onClick={() => customOpen(item)}
                className="cursor-pointer basis-auto w-32 md:w-40"
              >
                <img
                  src={item.giftcard_meta.countries[0].logo}
                  alt={item.name}
                  className="rounded-lg object-cover h-24 w-full md:h-32 shrink-0"
                />
                <p className="mt-2 text-xs truncate md:text-base text-center">{item.name}</p>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>

        <div className='mt-14'>
          <Select
            id="country"
            label="Select Country"
            variant="tertiary"
            tertiaryOptions={formatCountries}
            value={country}
            controlClassName='bg-white border border-gray-200'
            setValue={handleChange}
          />
        </div>

        <DashboardCardLayout className='mt-8'>
          <div className='flex space-x-3 md:space-x-7'>
            <button
              onClick={() => setPage('airtime')}
              className={cn('text-xs md:text-base text-grey-text p-2', [page === 'airtime' && 'text-platnova-purple font-medium border-b-2 border-platnova-purple'])}
            >
              Airtime
            </button>
            <button
              onClick={() => setPage('data')}
              className={cn('text-xs md:text-base text-grey-text p-2', [page === 'data' && 'text-platnova-purple font-medium border-b-2 border-platnova-purple'])}
            >
              Data
            </button>
            <button
              onClick={() => setPage('betting')}
              className={cn('text-xs md:text-base text-grey-text p-2', [page === 'betting' && 'text-platnova-purple font-medium border-b-2 border-platnova-purple'])}
            >
              Betting
            </button>
            <button
              onClick={() => setPage('cable')}
              className={cn('text-xs md:text-base text-grey-text p-2', [page === 'cable' && 'text-platnova-purple font-medium border-b-2 border-platnova-purple'])}
            >
              Cable & TV
            </button>
            <button
              onClick={() => setPage('electricity')}
              className={cn('text-xs md:text-base text-grey-text p-2', [page === 'electricity' && 'text-platnova-purple font-medium border-b-2 border-platnova-purple'])}
            >
              Electricity
            </button>
          </div>

          {page === 'airtime' && <Bills type='airtime' />}
          {page === 'data' && <Bills type='data' />}
          {page === 'betting' && <Bills type='betting' />}
          {page === 'cable' && <Bills type='cable' />}
          {page === 'electricity' && <Bills type='electricity' />}

        </DashboardCardLayout>

        <div className='grid grid-cols-2 gap-x-7 mt-14 max-w-[37.5rem] w-full pb-12'>
          <button onClick={() => dispatch(setComingSoonModalOpen(true))}>
            <img
              src='https://res.cloudinary.com/platnova/image/upload/v1723599490/assets/refill/insurance.png'
              alt='Insurance'
              className='object-contain'
            />
          </button>
          <button onClick={() => dispatch(setComingSoonModalOpen(true))}>
            <img
              src='https://res.cloudinary.com/platnova/image/upload/v1723599022/assets/refill/education.png'
              alt='Education'
              className='object-contain'
            />
          </button>
        </div>
      </div>
      <PurchaseModal isOpen={isOpen} close={close} />
    </PaddedContainer>
  );
};

export default Refill;
