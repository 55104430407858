import { handleErrors } from 'components/error/notifyError';
import { notifySuccess } from 'components/error/notifySuccess';
import { useFormik } from 'formik';
import { useGetDepositCardTransationLimits } from 'lib/utils';
import { useParams } from 'react-router-dom';
import { useDepositCard } from 'services/cards';
import { useGetWallets } from 'services/wallet';
import { useAppSelector } from 'store';
import { WALLET_ID_KEY, WALLET_SLICE_REDUCER_PATH } from 'store/slices/wallet/constants';
import { formatAmount, removeNonDigit } from 'utils/numberFormat';
import { number, object, string } from 'yup';

export const useDepositCardForm = () => {
  const { mutate: depositCard, error, isError, isPending } = useDepositCard();
  const { id } = useParams();

  const limits = useGetDepositCardTransationLimits();

  const { data: wallets } = useGetWallets();
  const { [WALLET_ID_KEY]: walletId } = useAppSelector((state) => state[WALLET_SLICE_REDUCER_PATH]);
  const activeWallet =
    walletId.length > 0 ? wallets?.data.find((x) => x.id === walletId) : wallets?.data[0];

  const formik = useFormik({
    initialValues: {
      currency: '',
      amount: '',
    },
    validationSchema: object().shape({
      currency: string().required(),
      amount: number()
        .transform((value, originalValue) => {
          return Number(removeNonDigit(String(originalValue)));
        })
        .min(
          limits?.min ?? 0,
          `Minimum ${formatAmount(String(limits?.min))} ${activeWallet?.type.toUpperCase()}`
        )
        .max(
          limits?.max ?? 0,
          `Maximum ${formatAmount(String(limits?.max))} ${activeWallet?.type.toUpperCase()}`
        )
        .required('Kindly input amount'),
    }),
    onSubmit: (values) => {
      const amount = Number(removeNonDigit(String(values.amount)));
      const newValues = { ...values, id: id as string, amount: amount };
      try {
        depositCard(newValues, {
          onSuccess: () => {
            notifySuccess('Card funded successfully');
            formik.resetForm();
          },
        });
      } catch (err) {
        handleErrors(err);
      }
    },
  });

  function getInputProps(id: keyof typeof formik.values) {
    return {
      ...formik.getFieldProps(id),
      ...formik.getFieldMeta(id),
      // ...formik.getFieldHelpers(id),
      ...formik.errors,
    };
  }

  return Object.freeze({
    onSubmit: formik.handleSubmit,
    setValue: formik.setFieldValue,
    isValid: formik.isValid,
    errors: formik.errors,
    depositError: error,
    isError: isError,
    isPending: isPending,
    values: formik.values,
    getInputProps,
    setError: formik.setErrors,
  });
};
