import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Airtime, DataCategory } from 'services/refill/constants';
import { GiftCard, GiftcardCountryMeta } from 'services/refill/types';

import { BILLS_COUNTRY_KEY, REFILL_SLICE_REDUCER_PATH, refillSliceInitialState } from './constants';

const refillSlice = createSlice({
  name: REFILL_SLICE_REDUCER_PATH,
  initialState: refillSliceInitialState,
  reducers: {
    resetRefillState: () => {
      return refillSliceInitialState;
    },
    setBillsCountry: (state, { payload }: PayloadAction<string>) => {
      state[BILLS_COUNTRY_KEY] = payload;
    },

    setDataPlans: (state, { payload }: PayloadAction<DataCategory>) => {
      state.plans = payload;
    },

    setActiveRefill: (state, { payload }: PayloadAction<GiftCard | Airtime>) => {
      state.activeRefill = {
        ...payload,
        giftcard_meta: 'giftcard_meta' in payload ? payload.giftcard_meta : undefined,
        vas_meta: 'vas_meta' in payload ? payload.vas_meta : undefined,
      };
    },

    setGiftcardCountry: (state, { payload }: PayloadAction<GiftcardCountryMeta>) => {
      state.giftcardCountry = payload;
    },
  },
});

export const refillReducer = refillSlice.reducer;

export const {
  resetRefillState,
  setBillsCountry,
  setActiveRefill,
  setGiftcardCountry,
  setDataPlans
} = refillSlice.actions;
