import Limit from 'assets/icons/limit.png';
import { Switch } from 'components/ui/switch';
import useDisclosure from 'hooks/useDisclosure';
import { ChevronLeft, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCards } from 'services/cards';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import SubRouteLayout from 'views/dashboard/components/subRouteLayout';

import LimitModal from './limitModal';

// type ProgressRingProps = {
//   radius: number;
//   stroke: number;
//   progress: number; // value between 0 and 100
// };

// const ProgressRing = ({ radius, stroke, progress }: ProgressRingProps) => {
//   const normalizedRadius = radius - stroke * 2;
//   const circumference = normalizedRadius * 2 * Math.PI;
//   const strokeDashoffset = circumference - (progress / 100) * circumference;

//   return (
//     <svg height={radius * 2} width={radius * 2}>
//       {/* Background Circle */}
//       <circle
//         stroke="#EDEDFB" // light background color
//         fill="transparent"
//         strokeWidth={stroke}
//         r={normalizedRadius}
//         cx={radius}
//         cy={radius}
//       />
//       {/* Progress Circle */}
//       <circle
//         stroke="url(#gradient)" // Gradient applied
//         fill="transparent"
//         strokeWidth={stroke}
//         strokeLinecap="round" // Rounded end for the stroke
//         strokeDasharray={`${circumference} ${circumference}`}
//         strokeDashoffset={strokeDashoffset}
//         r={normalizedRadius}
//         cx={radius}
//         cy={radius}
//         style={{
//           transition: 'stroke-dashoffset 0.35s',
//           transform: 'rotate(-90deg)',
//           transformOrigin: '50% 50%',
//         }}
//       />
//       {/* Gradient Definition */}
//       <defs>
//         <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="1">
//           <stop offset="0%" stopColor="#8B5CF6" /> {/* Purple */}
//           <stop offset="100%" stopColor="#6366F1" /> {/* Blue */}
//         </linearGradient>
//       </defs>
//     </svg>
//   );
// };

const EditCardLimits = () => {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  const {
    isOpen: limitModalIsOpen,
    close: closeLimitModal,
    open: openLimitModal,
  } = useDisclosure();

  const handleSwitchChange = (checked: boolean) => {
    setIsChecked(checked);
    if (checked) {
      openLimitModal();
    }
  };

  const { data: getCardsResponse, isPending: isLoadingGetCardsResponse } = useGetCards();

  const { id } = useParams();

  // const { data } = useGetCardEvents(id as string)

  const card = getCardsResponse?.data.find((card) => card.id === id);
  useEffect(() => {
    card?.info.control.limits === null ? setIsChecked(false) : setIsChecked(true);
  }, [card?.info.control.limits]);

  // const { isOpen, open, close } = useDisclosure()

  return (
    <SubRouteLayout header="Spending Limit">
      {card && !isLoadingGetCardsResponse ? (
        <div className="pb-20 xl:-ml-[4.688rem]">
          <div className="xl:flex items-start xl:space-x-11">
            <button
              onClick={() => navigate(-1)}
              className="flex space-x-1.5 xl:space-x-3 items-center"
            >
              <ChevronLeft className="w-3 h-3 xl:w-6 xl:h-6" />
              <span className="text-xs xl:text-base">Back</span>
            </button>

            <div className="space-y-10 max-w-[37.5rem]">
              <div className="space-y-3">
                <p className="text-lg md:text-2xl font-medium capitalize">Card limit</p>
                <p className="text-xs md:text-base text-black/70">
                  Control the spending limit for this card
                </p>
              </div>

              <div className="flex flex-col w-full items-center space-y-10">
                <DashboardCardLayout className="flex justify-between items-center">
                  <div className="flex space-x-6 items-center">
                    <img src={Limit} alt="limit" />
                    <div>
                      <p className="text-sm md:text-lg font-medium">Spending Limit</p>
                      <p className="text-xs md:text-lg text-grey-text">
                        Spending/ Withdrawal limit
                      </p>
                    </div>
                  </div>
                  <Switch checked={isChecked} onCheckedChange={handleSwitchChange} />
                </DashboardCardLayout>

                {/* {card?.info.control.limits !== null && isChecked && (
                  <div style={{ position: 'relative', width: '300px', height: '300px' }}>
                    <ProgressRing radius={150} stroke={10} progress={20} />
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ fontSize: '24px', fontWeight: 'bold' }}>₦345</span>
                      <div style={{ fontSize: '12px', color: '#6B7280' }}>Spent this month</div>
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <LimitModal close={closeLimitModal} isOpen={limitModalIsOpen} />
        </div>
      ) : (
        <Loader />
      )}
    </SubRouteLayout>
  );
};

export default EditCardLimits;
