import Channel from 'assets/icons/channel.png';
import Limit from 'assets/icons/limit.png';
import Button from 'components/Buttons/Button';
import { Switch } from 'components/ui/switch';
import { useFormik } from 'formik';
import useDisclosure from 'hooks/useDisclosure';
import { useState } from 'react';
import DashboardCardLayout from 'views/dashboard/components/dashboardCardLayout';
import { object } from 'yup';

import ChannelsModal from './channelsModal';
import LimitModal from './limitModal';

const LinkCardControlStage = () => {
  const formik = useFormik({
    initialValues: {},
    validationSchema: object().shape({}),
    onSubmit: () => {
      //
    },
  });

  // function getInputProps(id: keyof typeof formik.values) {
  //   return {
  //     ...formik.getFieldProps(id),
  //     ...formik.getFieldMeta(id),
  //     ...formik.errors,
  //   };
  // }

  const [isChecked, setIsChecked] = useState(false);

  const {
    isOpen: limitModalIsOpen,
    close: closeLimitModal,
    open: openLimitModal,
  } = useDisclosure();

  const handleSwitchChange = (checked: boolean) => {
    setIsChecked(checked);
    if (checked) {
      openLimitModal();
    }
  };

  const {
    isOpen: channelsModalIsOpen,
    close: closeChannelsModal,
    open: openChannelsModal,
  } = useDisclosure();

  return (
    <div>
      <div className="space-y-10">
        <div>
          <p className="text-lg md:text-2xl font-medium">Card control</p>
          <p className="text-xs md:text-base text-black/70"></p>
        </div>
        <form className="space-y-6" onSubmit={formik.handleSubmit}>
          <DashboardCardLayout className="flex justify-between items-center">
            <div className="flex space-x-6 items-center">
              <img src={Limit} alt="limit" />
              <div>
                <p className="text-lg font-medium">Spending Limit</p>
                <p className="text-lg text-grey-text">Spending/ Withdrawal limit</p>
              </div>
            </div>
            <Switch checked={isChecked} onCheckedChange={handleSwitchChange} />
          </DashboardCardLayout>

          <DashboardCardLayout className="flex justify-between items-center">
            <div className="flex space-x-6 items-center">
              <img src={Channel} alt="channel" />
              <div>
                <p className="text-lg font-medium">Channel</p>
                <p className="text-lg text-grey-text">Places to use the card</p>
              </div>
            </div>
            <Button
              type="button"
              variant="secondary"
              className="md:h-auto"
              onClick={openChannelsModal}
            >
              Change
            </Button>
          </DashboardCardLayout>

          <Button variant="purple" type="submit" className="w-full">
            Continue
          </Button>
        </form>
      </div>
      <LimitModal close={closeLimitModal} isOpen={limitModalIsOpen} />
      <ChannelsModal close={closeChannelsModal} isOpen={channelsModalIsOpen} />
    </div>
  );
};

export default LinkCardControlStage;
