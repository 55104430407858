import http from 'services/http';
import { GeneralApiResponse } from 'types';

import { VaultResponse, VaultWalletsResponse } from './types';

export const getVaults = async (): Promise<GeneralApiResponse<VaultResponse[]>> => {
  const res = await http.get(`/vaults`);
  return res.data;
};

export const getVaultWallets = async (): Promise<GeneralApiResponse<VaultWalletsResponse[]>> => {
  const res = await http.get(`/vaults/wallets`);
  return res.data;
};

export const postCreateVault = async (data: { plan_name: string; plan_currency: string; plan_amount: number; currency: string }): Promise<GeneralApiResponse<unknown>> => {
  const res = await http.post(`/vaults`, data);
  return res.data;
};