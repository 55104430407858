import { accountsRoutes } from './accounts';
import { cardsRoutes } from './cards';
import { refillRoutes } from './refill';
import { settingsRoutes } from './settings';
import { transactionsRoutes } from './transactions';
import { vaultRoutes } from './vault';

export default [
  ...accountsRoutes,
  ...settingsRoutes,
  ...transactionsRoutes,
  ...cardsRoutes,
  ...refillRoutes,
  ...vaultRoutes
];
