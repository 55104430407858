import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { GET_CARD_EVENTS_QUERY_KEY, GET_CARDS_QUERY_KEY } from './constants';
import {
  deleteCard,
  freezeCard,
  getCardEvents,
  getCards,
  postCreateVirtualCard,
  postDepositCard,
  postLinkCard,
  postOrderPhysicalCard,
  postWithdrawCard,
  resendCardPin,
  revealCard,
  unfreezeCard,
  updateCard,
} from './function';

export const useGetCards = () => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_CARDS_QUERY_KEY],
    queryFn: () => getCards(),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useGetCardEvents = (id: string) => {
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [GET_CARD_EVENTS_QUERY_KEY],
    queryFn: () => getCardEvents(id),
  });

  return {
    data,
    isPending,
    isError,
    refetch,
  };
};

export const useOrderPhysicalCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postOrderPhysicalCard,
  });

  return { mutate, isPending, isError, error };
};

export const useCreateVirtualCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postCreateVirtualCard,
  });

  return { mutate, isPending, isError, error };
};

export const useRevealCard = () => {
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: revealCard,
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useResendCardPin = () => {
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: resendCardPin,
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useFreezeCard = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: freezeCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CARDS_QUERY_KEY] });
    },
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useUnfreezeCard = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: unfreezeCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CARDS_QUERY_KEY] });
    },
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useDeleteCard = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: deleteCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CARDS_QUERY_KEY] });
    },
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useUpdateCard = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, isError, error, data } = useMutation({
    mutationFn: updateCard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_CARDS_QUERY_KEY] });
    },
  });

  return {
    mutate,
    isPending,
    isError,
    error,
    data,
  };
};

export const useDepositCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postDepositCard,
  });

  return { mutate, isPending, isError, error };
};

export const useWithdrawCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postWithdrawCard,
  });

  return { mutate, isPending, isError, error };
};

export const useLinkCard = () => {
  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: postLinkCard,
  });

  return { mutate, isPending, isError, error };
};
